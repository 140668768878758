import { Component } from "react";
import { withRouter } from "react-router-dom";

class ScrollToTop extends Component {
	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location ) {
			//const firstPath = window.location.pathname.split('/')[2].toLowerCase();
			//if (firstPath === "broadcast" || firstPath === "facility" || firstPath === "search") {
			if(this.props.location.pathname.includes("broadcast") || this.props.location.pathname.includes("facility") || this.props.location.pathname.includes("search")){
				window.scrollTo(0, 0);
			}
		
		}
	}

	render() {
		return this.props.children;
	}
}

export default withRouter(ScrollToTop);
