import React, { useState, useEffect } from "react";
import FacilityList from "../../contentLists/facilityList";
import LoadingPlaceholder from "../loadingPlaceholder/LoadingPlaceholder";
import styles from "../Styles.module.css";
import useEffectSkipFirst from "../../utils/useEffectSkipFirst";
import Icon from "../../../img/Baseball-diamond-icon.svg";

import fetchFacilities from "./fetchFacilities";

export default function GetBroadcasts({
	searchQuery,
	isLoading,
	numOfItemsDefault,
	numOfItemsToIncrement,
}) {
	const [state, setState] = useState({
		facilities: [],
		isGettingMore: false,
		page: 1,
		perPage: numOfItemsDefault,
		numOfPages: null,

		isLoading: true,
		reset: false,
	});
	const [errorMessage, setErrorMessage] = useState(false);

	useEffect(() => {
		if (!isLoading) {
			getFacilities();
		}
	}, [isLoading]);

	useEffectSkipFirst(() => {
		getFacilities();
	}, [state.page]);

	useEffectSkipFirst(() => {
		resetData();
	}, [searchQuery]);

	useEffectSkipFirst(() => {
		if (state.reset) {
			getFacilities();
		}
	}, [state.reset]);

	const getFacilities = async () => {
		fetchFacilities(searchQuery, state.perPage, state.page)
			.then((res) => {
				setState({
					...state,
					facilities: [...state.facilities, ...res.facilities],
					numOfPages: res.total_pages,
					reset: false,
					isLoading: false,
					isGettingMore: false,
				});
			})
			.catch((e) => {
				setErrorMessage(true);
				setState({ ...state, isLoading: false });
			});
	};

	const showMoredata = () => {
		setState({
			...state,
			isGettingMore: true,
			page: state.page + 1,
			perPage: numOfItemsToIncrement,
		});
	};
	const resetData = () => {
		setState({
			facilities: [],
			isGettingMore: false,
			page: 1,
			perPage: 6,
			numOfPages: null,

			isLoading: true,
			reset: true,
		});
	};
	return (
		<>
			<div className={styles.headingRow}>
				<div className={styles.icon}>
					<img src={Icon} />
				</div>
				<h3 className={styles.sectionTitle}>FACILITIES</h3>
			</div>
			{state.isLoading || isLoading ? (
				<LoadingPlaceholder />
			) : state.facilities.length === 0 && !errorMessage ? (
				<div className={styles.noDataMessage}>
					<p>There are no facilities to display.</p>
				</div>
			) : errorMessage ? (
				<div className={styles.errMessage}>
					<p>
						There was a problem fetching facilities. Please let us know at{" "}
						<a href="mailto:support@muscovision.com">support@muscovision.com</a>{" "}
						if this continues to occur.
					</p>
				</div>
			) : (
				<FacilityList
					data={state.facilities}
					numOfColumns={3}
					breakpoint="800"
					showMoreData={showMoredata}
					showLessData={resetData}
					isGettingMore={state.isGettingMore}
					moreToShow={state.page >= state.numOfPages ? false : true}
				/>
			)}
		</>
	);
}
