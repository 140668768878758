import React from "react";
import * as moment from "moment";
import ShareIcon from "../../../../img/share-icon.svg";
import ActionBtn from "./actionBtn/ActionBtn";
import styles from "../Broadcast.module.css";
import { setCurrentModal } from "../../../../redux/actions/modal";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import LoadingPlaceholder from "./loadingPlaceholder/LoadingPlaceholder";

export default function Info({ broadcastData, isLoading }) {
	const screenWidth = useSelector((state) => state.general.screenWidth);
	const dispatch = useDispatch();
	const getEndTime = (date, increase) => {
		var startdate = moment(date);
		var returned_endate = moment(startdate).add(increase, "seconds");
		return returned_endate;
	};

	return (
		<section className={styles.broadcastInfo}>
			{!isLoading ? (
				<>
					<div>
						<span className={styles.gameTitle}>{broadcastData.title}</span>
						{/* <span className={styles.locationTitle}>
							{broadcastData.location} 
						</span> */}
					</div>
					<div className={styles.broadcastInfoBottom}>
						<span className={styles.date}>
							<Moment format="MMM DD YYYY" date={broadcastData.date} />
							<p className={styles.broadcastTime}>
								<span>
									<Moment format="hh:mm A" date={broadcastData.date} />
								</span>
								<span>&nbsp;-&nbsp;</span>

								<span>
									<Moment
										format="hh:mm A"
										date={getEndTime(
											broadcastData.date,
											// change to regular duration
											broadcastData.expected_duration
										)}
									/>
								</span>
							</p>
						</span>
						<div className={styles.headerButtons}>
							<button onClick={() => dispatch(setCurrentModal("share"))}>
								<img src={ShareIcon} alt="Share Icon" />
								{screenWidth > 450 ? <span>SHARE</span> : null}
							</button>
							<ActionBtn />
						</div>
					</div>
				</>
			) : (
				<LoadingPlaceholder />
			)}
		</section>
	);
}
