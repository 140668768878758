import React, { useState } from "react";
import styles from "./Filters.module.css";

const SearchBar = ({ onChange, refinedSearch }) => {
	const setSearchStr = (value) => {
		onChange(value);
	};

	return (
		<div className={styles.searchFilter}>
			<span className={styles.filterHeader}>REFINE SEARCH</span>

			<input
				data-testid="input-field"
				type="text"
				placeholder="SEARCH"
				value={refinedSearch}
				onChange={(e) => setSearchStr(e.target.value)}
				maxLength="180"
			/>
		</div>
	);
};

export default SearchBar;
