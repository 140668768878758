import React from "react";
import styles from "../Auth.module.css";
import MVLogo from "../../../../img/MuscoVision_with-tag-gray-01.svg";
import SuccessAnimation from "./successAnimation";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../../redux/actions/modal";
import * as moment from "moment";
export default function PaymentConfirmation({ selectedSubscription }) {
	const dispatch = useDispatch();
	const getPassExpiration = () => {
		var exp = moment()
			.add(selectedSubscription.passLength, "days")
			.format("MMMM DD, YYYY");
		// let exp = moment(new Date() + selectedPass.amount).format("MMMM DD, YYYY");
		return exp;
	};
	return (
		<div className={styles.paymentConfirmation}>
			<img
				// className={styles.nearbyIcon}
				src={MVLogo}
				alt="Logo"
			/>
			<SuccessAnimation />
			<h3>
				THANK YOU FOR
				<br />
				YOUR PURCHASE!
			</h3>
			<button
				onClick={() => dispatch(closeModal())}
				className={styles.submitBtn}
				type="button"
			>
				START WATCHING
			</button>
			{selectedSubscription ? (
				<p className={styles.subscriptionMessage} style={{ marginTop: "15px" }}>
					<span>NOTE: </span>
					{`This subscription will end on ${getPassExpiration()}.`}
				</p>
			) : null}
			<p className={styles.infoText}>
				We have recieved your payment information and are processing your
				payment. You will recieve a confirmation email at the address you
				provided.
			</p>
		</div>
	);
}
