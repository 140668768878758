import { SET_USER_DATA, SET_PAYMENT_SUCCEEDED } from "../actionTypes";

const initialState = {
	userData: null,
	paymentSucceeded: false,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case SET_USER_DATA:
			return {
				...state,
				userData: action.payload,
			};
		case SET_PAYMENT_SUCCEEDED:
			return {
				...state,
				paymentSucceeded: action.payload,
			};

		default:
			return state;
	}
};
