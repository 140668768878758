import React, { useState, useRef } from "react";
import {
	EmailShareButton,
	FacebookShareButton,
	TwitterShareButton,
} from "react-share";
import FacebookIcon from "../../../img/Fb_color.svg";
import TwitterIcon from "../../../img/Tw_color.svg";
import MailIcon from "../../../img/Mail.svg";

import styles from "./ShareLink.module.css";
export default function Share() {
	const [isCopied, setIsCopied] = useState(false);
	const textToCopy = useRef();
	const copyLink = () => {
		textToCopy.current.select();
		document.execCommand("copy");
		setIsCopied(true);
	};

	return (
		<div className={styles.share}>
			<div className={styles.row}>
				<FacebookShareButton
					className={styles.socialbtn}
					url={window.location.href}
					title={"Facebook"}
				>
					<img src={FacebookIcon} />
					<p style={{ color: "black" }}>Facebook</p>
				</FacebookShareButton>
				<TwitterShareButton
					className={styles.socialbtn}
					url={window.location.href}
					title={"Twitter"}
				>
					<img src={TwitterIcon} />
					<p style={{ color: "black" }}>Twitter</p>
				</TwitterShareButton>
				<EmailShareButton
					className={styles.socialbtn}
					url={window.location.href}
					title={"Email"}
				>
					<img src={MailIcon} />
					<p style={{ color: "black" }}>Email</p>
				</EmailShareButton>
			</div>
			<div className={styles.copyLinkRow} onClick={copyLink}>
				<input
					ref={textToCopy}
					className={styles.copyLinkTxt}
					type="text"
					value={window.location.href}
				/>
				<button className={styles.copyLinkBtn}>
					{isCopied ? "COPIED" : "COPY"}
				</button>
			</div>
		</div>
	);
}
