import React from "react";
import styles from "./Nearby.module.css";
import { Link } from "react-router-dom";
import Spinner from "./spinner/Spinner";

import LocationMarker from "../../../img/location-marker.svg";
import BoltIcon from "../../../img/Bolt-icon.svg";

export default function SidebarList({
	data,
	currentLocation,
	setSelected,
	isLoading,
	currentlySelected,
}) {
	const renderItems = () => {
		let returnedContent;
		if (data.length > 0) {
			returnedContent = data.map((item, index) => {
				let activeStyles;
				if (currentlySelected == item.id) {
					activeStyles = {
						backgroundColor: "rgba(0, 94, 164, 0.05)",
						border: "2px solid rgb(0, 94, 164)",
					};
				}

				return (
					<div
						className={styles.listItem}
						id={item.id}
						style={activeStyles}
						onClick={() => setSelected(item.id)}
						key={item.id}
					>
						<h6 style={{ fontFamily: "Erbaum-Regular", marginBottom: "5px" }}>
							{item.title}
						</h6>

						<div style={{ display: "flex", justifyContent: "space-between" }}>
							{currentLocation !== null && item.distance !== undefined ? (
								<span style={{ display: "flex" }}>
									<img src={LocationMarker} alt="Location Marker" />
									&nbsp;
									<p style={{ width: "100%" }}>
										<span>
											{Math.round(item.distance).toLocaleString("en")}
										</span>
										<span> miles</span>
									</p>
								</span>
							) : null}
						</div>
						<Link
							className={styles.viewFacilityLink}
							to={`/facility/${item.id}`}
						>
							<button>
								<img src={BoltIcon} />
								VIEW FACILITY
							</button>
						</Link>
					</div>
				);
			});
		} else {
			returnedContent = (
				<p className={styles.noDataMessage}>
					There are no facilities in this area.{" "}
				</p>
			);
		}

		return returnedContent;
	};
	return (
		<div id="select" className={styles.sidebarList}>
			{data !== null ? (
				renderItems()
			) : isLoading ? (
				<div className={styles.spinnerContainer}>
					<Spinner color="255, 255, 255" />
				</div>
			) : null}
		</div>
	);
}
