import { SET_USER_DATA, SET_PAYMENT_SUCCEEDED } from "../actionTypes";

export const setUserData = (data) => ({
	type: SET_USER_DATA,
	payload: data,
});

export const setPaymentSucceeded = (bool) => ({
	type: SET_PAYMENT_SUCCEEDED,
	payload: bool,
});
