import React, { useState } from "react";
import { Auth, Hub } from "aws-amplify";
import styles from "./Auth.module.css";

import { useDispatch } from "react-redux";
import {
	setCurrentModal,
	setLoading,
	closeModal,
} from "../../../redux/actions/modal";
import { toggleLogin } from "../../../redux/actions/auth";

export default function SendConfirmationCode() {
	const [error, setError] = useState("");
	const [email, setEmail] = useState("");

	return (
		<div className={styles.formWrapper}>
			<div className={styles.formRow}>
				<div className={styles.inputGroup}>
					<label className={styles.label}>EMAIL</label>
					<input
						autoFocus
						type="email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						placeholder="EMAIL ADDRESS"
					/>
					{error ? <span className={styles.errorMessage}>{error}</span> : null}
				</div>
			</div>
		</div>
	);
}
