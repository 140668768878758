import React from "react";
import styles from "./LoadingPlaceholder.module.css";

import { motion } from "framer-motion";
export default function LoadingPlaceholder() {
	return (
		<motion.div
			className={styles.placeholderBlock}
			animate={{ opacity: 0.25 }}
			transition={{
				yoyo: Infinity,
				duration: 0.75,
				// delay: i * 0.2,
			}}
		>
			<div className={styles.placeholderTitle}></div>
			<div className={styles.row}>
				<div>
					<div className={styles.placeholderDate1}></div>
					<div className={styles.placeholderDate2}></div>
				</div>
				<div>
					<div className={styles.actions}></div>
				</div>
			</div>
		</motion.div>
	);
}
