import {
	CLOSE_MODAL,
	SET_CURRENT_MODAL,
	SET_LOADING,
	SET_ERROR_DATA,
	NEW_TAG_ADDED,
} from "../actionTypes";

const initialState = {
	modalOpen: false,
	currentModal: null,
	loading: false,
	loadingMessage: "",
	errorData: null,
	newTagAdded: false,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case CLOSE_MODAL:
			return {
				...state,
				modalOpen: false,
			};
		case SET_CURRENT_MODAL:
			return {
				...state,
				currentModal: action.payload,
				modalOpen: true,
			};

		case SET_LOADING:
			return {
				...state,
				loading: action.payload.isLoading,
				loadingMessage: action.payload.message,
			};

		case SET_ERROR_DATA:
			return {
				...state,
				errorData: action.payload,
			};
		case NEW_TAG_ADDED:
			return {
				...state,
				newTagAdded: action.payload,
			};

		default:
			return state;
	}
};
