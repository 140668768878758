const fetchBroadcasts = (filters, facilityID, cursor, perPage, viewerStatus) => {
	let startDate = "";
	let endDate = "";
	let sports = "";
	let searchQuery = "";
	let sort = viewerStatus === "archived" ? "desc" : "asc";

	if (filters !== null && filters !== undefined) {
		startDate = filters.dates.startDate;
		endDate = filters.dates.endDate;
		sports = filters.sports;
		searchQuery = filters.searchQuery;
	}

	return new Promise((resolve, reject) => {
		fetch(
			`https://arbiter.musco.io/pub/broadcast?sort_by=date&sort=${sort}&site_id=${facilityID}&search=${searchQuery}&before=${endDate}&after=${startDate}&sport_type=${sports}&cursor=${cursor}&per_page=${perPage}&status=${viewerStatus}`
		)
			.then((response) => response.json())
			.then((result) => {
				resolve(result);
			})
			.catch((e) => {
				reject();
			});
	});
};

export default fetchBroadcasts;
