const fetchFacilities = (searchQuery, perPage, page) => {
	return new Promise((resolve, reject) => {
		fetch(`https://arbiter.musco.io/pub/facility?per_page=${perPage}&page=${page}&search=${searchQuery}`)
			.then((response) => response.json())
			.then((result) => {
				resolve(result);
			})
			.catch((err) => {
				reject();
			});
	});
};

export default fetchFacilities;
