import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import styles from "./Auth.module.css";
import { Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCurrentModal } from "../../../redux/actions/modal";
import { toggleLogin } from "../../../redux/actions/auth";
import ReactGA from "react-ga4";

export default function ConfirmAccount({ credentials }) {
	const dispatch = useDispatch();
	const [errorMessage, setErrorMessage] = useState(null);
	const [readyToPay, setReadyToPay] = useState(false);
	const [code, setCode] = useState("");
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(null);
	const [codeResent, setCodeResent] = useState(false);
	useEffect(() => {
		// ReactGA.pageview("confirm_account_modal");
	}, []);

	const handleConfirmation = async (e) => {
		e.preventDefault();
		setErrorMessage(null);

		if (code === "") {
			setErrorMessage("Please enter confirmation code");
		} else {
			setLoading(true);
			await Auth.confirmSignUp(credentials.email, code)
				.then(() => {
					//    If account is confirmed successful, attempt to sign the user in automatically
					//    with the username and password they entered on the previous modal.
					//    If that fails, redirect them to the login modal
					Auth.signIn(credentials.email, credentials.password)
						.then((res) => {
							dispatch(toggleLogin(true));
							setSuccess("Account successfully confirmed.");
							setReadyToPay(true);
							setLoading(false);
						})
						.catch(() => {
							dispatch(setCurrentModal("login"));
							setLoading(false);
						});
				})
				.catch(() => {
					setErrorMessage("Verification code is invalid");
					setLoading(false);
				});
		}
	};
	const resendConfirmationCode = async () => {
		setCodeResent(false);
		setLoading(true);
		try {
			await Auth.resendSignUp(credentials.email);
			setCodeResent(true);
		} catch (error) {
			setErrorMessage(
				"There was a problem re-sending the confirmation code. If this problem continues to occur, please contact support."
			);
		} finally {
			setLoading(false);
		}
	};
	if (readyToPay) {
		return (
			<Redirect
				to={{
					pathname: "/pay",
				}}
			/>
		);
	}

	return (
		<div className={styles.formWrapper}>
			<form onSubmit={handleConfirmation}>
				<h3
					style={{
						color: "rgb(214, 214, 214)",
						marginBottom: "1.5rem",
						textAlign: "center",
					}}
				>
					CONFIRM YOUR ACCOUNT
				</h3>

				<p className={styles.generalMessage}>
					We have sent a confirmation code to:{" "}
					<span style={{ fontWeight: "bold", color: "white" }}>{credentials.email}</span>.
					<br />
					Please enter your code into the box below to verify your account.
					<br />
					<br />
				</p>
				{success ? (
					<p className={styles.successMessage}>{success}</p>
				) : (
					<>
						<div className={styles.inputGroup}>
							<label className={styles.label}>CONFIRMATION CODE</label>
							<input
								type="text"
								value={code}
								disabled={loading || success}
								onChange={(e) => setCode(e.target.value)}
								placeholder="Confirmation Code"
							/>
							{errorMessage ? <span className={styles.errorMessage}>{errorMessage}</span> : null}
						</div>

						<button
							type="submit"
							className={styles.submitBtn}
							onClick={handleConfirmation}
							disabled={loading || success}
						>
							{loading ? "CONFIRMING ACCOUNT..." : "CONFIRM"}
						</button>

						<span className={styles.resendInfo}>
							Didn't receive an email? Try checking your junk/spam folders OR{" "}
							<button type="button" onClick={resendConfirmationCode}>
								resend code
							</button>
							.
						</span>
						{codeResent && <p className={styles.successMessageSmall}>Confirmation code has been sent.</p>}
					</>
				)}
			</form>
		</div>
	);
}
