import React from "react";
import "./animation.css";
export default function index() {
	return (
		<svg id="success-animation" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="-23 -10 180.2 180.2">
			<circle
				class="path circle"
				fill="none"
				stroke="#005ea4"
				stroke-width="10"
				stroke-miterlimit="10"
				cx="65.1"
				cy="65.1"
				r="62.1"
			/>
			<polyline
				class="path check"
				fill="none"
				stroke="#005ea4"
				stroke-width="10"
				stroke-linecap="round"
				stroke-miterlimit="10"
				points="100.2,40.2 51.5,88.8 29.8,67.5 "
			/>
		</svg>
	);
}
