import React, { useState } from "react";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";

import styles from "./Payment.module.css";
import { useDispatch } from "react-redux";

export default function CheckoutForm({ clientSecret, setPaymentSucceeded }) {
	const stripe = useStripe();
	const elements = useElements();

	const [message, setMessage] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [formIsReady, setFormIsReady] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!stripe || !elements || isLoading || !formIsReady) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}

		setIsLoading(true);

		await stripe
			.confirmPayment({
				elements,
				confirmParams: {
					return_url: window.location.href,
				},
				redirect: "if_required",
			})
			.then((res) => {
				let { error } = res;
				let { paymentIntent } = res;
				if (error) {
					console.log(error.message);
					setMessage(error.message);
					setIsLoading(false);
					return;
				} else if (paymentIntent) {
					stripe
						.retrievePaymentIntent(clientSecret)
						.then(({ paymentIntent }) => {
							switch (paymentIntent.status) {
								case "succeeded":
									setMessage("Payment succeeded.");
									setPaymentSucceeded(true);
									break;
								case "processing":
									setMessage(
										"Your payment is processing. You will receive a confirmation email once the transaction is successful."
									);
									setIsLoading(false);
									break;
								case "payment_failed":
									setMessage("Your payment was not successful. Please try again.");
									setIsLoading(false);
									break;
								default:
									setMessage("Something went wrong. Please try again.");
									setIsLoading(false);
									break;
							}
						})
						.catch((error) => {
							setMessage("An unexpected error occurred. Please try again.");
							setIsLoading(false);
						});
				}
			})
			.catch((error) => {
				setMessage("An unexpected error occurred. Please try again.");
				setIsLoading(false);
			});
	};
	return (
		<>
			<div className={styles.horizontalRule}></div>

			<form id="payment-form" onSubmit={handleSubmit}>
				{message && (
					<div className={styles.errorMessage} style={{ marginBottom: "1rem" }}>
						{message}
					</div>
				)}

				<PaymentElement onReady={() => setFormIsReady(true)} id="payment-element" className={styles.paymentElement} />

				<button disabled={isLoading || !stripe || !elements || !formIsReady} className={styles.submitBtn}>
					{isLoading ? "PROCESSING..." : "CONFIRM PAYMENT"}
				</button>
			</form>
		</>
	);
}
