import { combineReducers } from "redux";
import auth from "./auth";
import modal from "./modal";
import general from "./general";
import user from "./user";

export default combineReducers({
	auth,
	modal,
	general,
	user,
});
