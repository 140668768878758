import React, { useState, useEffect } from "react";
import styles from "./Facilities.module.css";
import GetFacilities from "../../../components/data/getFacilities/GetFacilities";

function Facilities() {
	return (
		<div className={styles.facilities}>
			<GetFacilities
				searchQuery=""
				isLoading={false}
				numOfItemsDefault={6}
				numOfItemsToIncrement={6}
			/>
		</div>
	);
}
export default Facilities;
