import React, { useState, useEffect } from "react";
import styles from "../Profile.module.css";
import { Auth } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, setCurrentModal } from "../../../../redux/actions/modal";
import { Link } from "react-router-dom";
import * as moment from "moment";
export default function Subscription({ user, refreshData }) {
	const dispatch = useDispatch();
	const paymentSucceeded = useSelector((state) => state.user.paymentSucceeded);
	const [subscriptionEnd, setSubscriptionEnd] = useState(null);

	useEffect(() => {
		// Check if subscription exists and if its expired.
		let passExpiration = new Date(user.attributes["custom:passEnd"]);

		if (passExpiration > new Date()) {
			setSubscriptionEnd(passExpiration);
		}
	}, []);

	useEffect(() => {
		// This is so subscription message updates after a payment is made.
		if (paymentSucceeded) {
			refreshData();
		}
	}, [paymentSucceeded]);
	return (
		<div className={styles.accountItem}>
			<h3>SUBSCRIPTION</h3>
			<br />
			{subscriptionEnd !== null ? (
				<p>{`Your subscription will end on ${moment(subscriptionEnd).format("MMMM DD, YYYY")}.`}</p>
			) : (
				<>
					<p>You do not currently have a valid subscription.</p>
					<p>
						<Link to="/pay" className={styles.subscribeBtn}>
							<button className={styles.subscribeBtn} onClick={() => dispatch(closeModal(true))}>
								Subscribe here
							</button>
						</Link>{" "}
						to get full access to all broadcasts.
					</p>
				</>
			)}
		</div>
	);
}
