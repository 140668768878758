import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./Backdrop.module.css";
import { toggleSideDrawer } from "../../../redux/actions/general";
const Backdrop = () => {
	const dispatch = useDispatch();
	const sideDrawerOpen = useSelector((state) => state.general.sideDrawerOpen);
	return sideDrawerOpen ? (
		<div
			className={styles.backdrop}
			onClick={() => dispatch(toggleSideDrawer())}
		></div>
	) : null;
};
export default Backdrop;
