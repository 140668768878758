import React, { useState } from "react";
import styles from "../Profile.module.css";
import { Auth } from "aws-amplify";

export default function Email({ email, user, refreshData }) {
	const [editModeEnabled, setEditModeEnabled] = useState(false);
	const [value, setValue] = useState(email);
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(false);
	const [confirmationCode, setConfirmationCode] = useState("");
	const [displayConfirmationField, setDisplayConfirmationField] = useState(false);

	const submit = async () => {
		if (value !== email) {
			await Auth.updateUserAttributes(user, {
				email: value,
			})
				.then((res) => {
					console.log(res);
					setSuccess(
						"We have sent a verification code to your updated email address. Please enter this code in the box above to verify this new email address is yours."
					);
					setError(null);
					setDisplayConfirmationField(true);
					// setEditModeEnabled(false);
					refreshData();
				})
				.catch((err) => {
					console.log(err);
					switch (err.message) {
						case "An account with the given email already exists.":
							setError(err.message);
							break;
						case "Invalid email address format.":
							setError(err.message);
							break;

						default:
							setError("We are unable to update your email.");
					}
				});
		}
	};

	const confirm = async () => {
		await Auth.verifyCurrentUserAttributeSubmit("email", confirmationCode)
			.then((res) => {
				setSuccess("Your email has been verified.");
				setDisplayConfirmationField(false);
				setEditModeEnabled(false);
			})
			.catch((err) => {
				console.log(err);
			});
	};
	return (
		<div className={styles.accountItem}>
			<div className={styles.row}>
				<div className={styles.left}>
					<h3>EMAIL</h3>
					<br />
					{editModeEnabled ? (
						<>
							{!displayConfirmationField ? (
								<input
									className={styles.textInput}
									type="text"
									value={value}
									onChange={(e) => setValue(e.target.value)}
								/>
							) : (
								<>
									<input
										className={styles.textInput}
										type="text"
										placeholder="Confirmation Code"
										value={confirmationCode}
										onChange={(e) => setConfirmationCode(e.target.value)}
									/>
									<div className={styles.buttonGroup}>
										<button className={styles.save} type="button" onClick={() => confirm()}>
											Confirm
										</button>
									</div>
								</>
							)}
						</>
					) : (
						<p>{email}</p>
					)}
				</div>
				{!editModeEnabled ? (
					<button className={styles.edit} type="button" onClick={() => setEditModeEnabled(true)}>
						Edit
					</button>
				) : null}
			</div>
			{error ? <p style={{ color: "red", marginTop: "10px" }}>{error}</p> : null}
			{success ? <p style={{ marginTop: "10px", color: "green" }}>{success}</p> : null}

			{editModeEnabled && !displayConfirmationField ? (
				<div className={styles.buttonGroup}>
					<button className={styles.save} type="button" onClick={() => submit()}>
						Save
					</button>
					<button
						className={styles.cancel}
						type="button"
						onClick={() => {
							setEditModeEnabled(false);
							setError(null);
						}}
					>
						Cancel
					</button>
				</div>
			) : null}
		</div>
	);
}
