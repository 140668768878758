import React, { useState } from "react";
import styles from "../Profile.module.css";
import { Auth } from "aws-amplify";

export default function Password({ user, refreshData }) {
	const [editModeEnabled, setEditModeEnabled] = useState(false);
	const [currentPW, setCurrentPW] = useState("");
	const [newConfirmPW, setNewConfirmPW] = useState("");
	const [newPW, setNewPW] = useState("");
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(false);

	const submit = async () => {
		//validate
		if (currentPW.length === 0) {
			setError("Please enter your current password.");
		} else if (newPW.length === 0) {
			setError("Please enter your new password.");
		} else if (newConfirmPW !== newPW) {
			setError("Passwords do not match.");
		} else {
			await Auth.changePassword(user, currentPW, newPW)
				.then((data) => {
					console.log(data);
					setSuccess(true);
					setError(null);
					setEditModeEnabled(false);
					refreshData();
				})
				.catch((err) => {
					console.log(JSON.stringify(err));
					switch (err.message) {
						default:
							setError(
								"We are unable to update your password. Please make sure that your current password is correct and your new password is atleast 6 characters."
							);
					}
				});
		}
	};
	return (
		<div className={styles.accountItem}>
			<div className={styles.row}>
				<div className={styles.left}>
					<h3>PASSWORD</h3>

					{editModeEnabled ? (
						<>
							<br />
							<label>CURRENT PASSWORD</label>
							<input
								style={{ marginBottom: "10px" }}
								className={styles.textInput}
								type="password"
								onChange={(e) => setCurrentPW(e.target.value)}
							/>

							<label>CREATE PASSWORD</label>
							<input
								style={{ marginBottom: "10px" }}
								className={styles.textInput}
								type="password"
								onChange={(e) => setNewPW(e.target.value)}
							/>

							<label>CONFIRM NEW PASSWORD</label>
							<input
								className={styles.textInput}
								type="password"
								onChange={(e) => setNewConfirmPW(e.target.value)}
							/>
						</>
					) : null}
				</div>
				{!editModeEnabled ? (
					<button
						className={styles.edit}
						type="button"
						onClick={() => setEditModeEnabled(true)}
					>
						Edit
					</button>
				) : null}
			</div>
			{error ? (
				<p style={{ color: "red", marginTop: "10px" }}>{error}</p>
			) : null}
			{success ? (
				<p style={{ color: "green", marginTop: "10px" }}>
					Your password has been updated.
				</p>
			) : null}

			{editModeEnabled ? (
				<div className={styles.buttonGroup}>
					<button
						className={styles.save}
						type="button"
						onClick={() => submit()}
					>
						Save
					</button>
					<button
						className={styles.cancel}
						type="button"
						onClick={() => {
							setEditModeEnabled(false);
							setError(null);
						}}
					>
						Cancel
					</button>
				</div>
			) : null}
		</div>
	);
}
