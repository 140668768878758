// TODO
// Handle errors
// Populate email automatically
// add instructional messaging

import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import styles from "./Auth.module.css";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentModal, setLoading, closeModal } from "../../../redux/actions/modal";
import { toggleLogin } from "../../../redux/actions/auth";
import ReactGA from "react-ga4";

export default function ConfirmAccount() {
	const dispatch = useDispatch();
	const history = useHistory();
	const userToConfirm = useSelector((state) => state.auth.userToConfirm);
	const [errorMessage, setErrorMessage] = useState(null);
	const [code, setCode] = useState("");
	const [email, setEmail] = useState(userToConfirm.email);
	const [password, setPassword] = useState(userToConfirm.password);
	const [codeResent, setCodeResent] = useState(false);
	useEffect(() => {
		// ReactGA.pageview("confirm_account_modal");
	}, []);
	const handleConfirmation = async (e) => {
		e.preventDefault();
		setErrorMessage(null);

		if (code === "") {
			setErrorMessage("Please enter confirmation code");
		} else {
			dispatch(setLoading({ isLoading: true, message: "Loading..." }));
			await Auth.confirmSignUp(email, code)
				.then(() => {
					//    If account is confirmed successful, attempt to sign the user in automatically
					//    with the username and password they entered on the previous modal.
					//    If that fails, redirect them to the login modal
					Auth.signIn(email, password)
						.then((res) => {
							dispatch(toggleLogin(true));
							dispatch(setLoading({ isLoading: false, message: "" }));
							dispatch(closeModal());
							history.go(0);
						})
						.catch(() => {
							dispatch(setCurrentModal("login"));
							dispatch(setLoading({ isLoading: false, message: "" }));
						});
				})
				.catch(() => {
					setErrorMessage("Verification code is invalid");
					dispatch(setLoading({ isLoading: false, message: "" }));
				});
		}
	};

	const resendConfirmationCode = async () => {
		setCodeResent(false);
		dispatch(setLoading({ isLoading: true, message: "Sending Confirmation Code" }));
		try {
			await Auth.resendSignUp(email);
			setCodeResent(true);
		} catch (error) {
			setErrorMessage(
				"There was a problem re-sending the confirmation code. If this problem continues to occur, please contact support."
			);
		} finally {
			dispatch(setLoading({ isLoading: false, message: "" }));
		}
	};

	return (
		<div className={styles.formWrapper}>
			<form onSubmit={handleConfirmation}>
				<h3
					style={{
						color: "black",
						marginBottom: "1.5rem",
						textAlign: "center",
					}}
				>
					CONFIRM YOUR ACCOUNT
				</h3>
				<p className={styles.generalMessage}>
					We have sent a confirmation code to: <span style={{ color: "rgb(0, 93, 164)" }}>{email}</span>.<br />
					<br />
					Please enter your code into the box below to verify your account.
					<br />
					<br />
				</p>
				<div className={styles.inputGroup}>
					<label className={styles.label}>CONFIRMATION CODE</label>
					<input type="text" value={code} onChange={(e) => setCode(e.target.value)} placeholder="Confirmation Code" />
					{errorMessage ? <span className={styles.errorMessage}>{errorMessage}</span> : null}
				</div>

				<button type="submit" className={styles.submitBtn} onClick={handleConfirmation}>
					CONTINUE
				</button>
				<span className={styles.resendInfo}>
					Didn't receive an email? Try checking your junk/spam folders OR{" "}
					<button type="button" onClick={resendConfirmationCode}>
						resend code
					</button>
					.
				</span>
				{codeResent && <p className={styles.successMessageSmall}>Confirmation code has been sent.</p>}
			</form>
		</div>
	);
}
