import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import "./MobileList.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "../Styles.module.css";
import Spinner from "../../../general/spinner/Spinner";
import LoadMoreArrow from "../../../../img/load-more-arrow.svg";

const MobileList = ({ data, showMoreData, isGettingMore, moreToShow }) => {
	var settings = {
		dots: false,
		infinite: false,
		speed: 200,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		centerMode: true,
		draggable: true,
	};

	return (
		<div className="mobileMediaList">
			<Slider {...settings}>
				{data.length !== 0
					? data.map((item, index) => {
							return (
								<Link
									key={item.id}
									to={`/facility/${item.id}`}
									className="itemLink"
								>
									<div
										className={styles.contentBlockImg}
										style={{ backgroundImage: `url(${item.medium_image})` }}
									></div>
									<h6 className="itemTitle" style={{ color: "white" }}>
										{item.title}
									</h6>
								</Link>
							);
					  })
					: null}
				{moreToShow ? (
					<div className="loadMoreBtnWrapper">
						{isGettingMore ? (
							<Spinner color="255, 255, 255" />
						) : (
							<button className="loadMoreBtn" onClick={() => showMoreData()}>
								LOAD MORE
								<img src={LoadMoreArrow} />
							</button>
						)}
					</div>
				) : null}
			</Slider>
		</div>
	);
};
export default MobileList;
