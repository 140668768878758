import { TOGGLE_LOGIN, SET_USER_TO_CONFIRM } from "../actionTypes";

const initialState = {
	isAuthenticated: false,
	userToConfirm: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case TOGGLE_LOGIN:
			return {
				...state,
				isAuthenticated: action.payload,
			};
		case SET_USER_TO_CONFIRM:
			return {
				...state,
				userToConfirm: action.payload,
			};

		default:
			return state;
	}
};
