import React, { useRef, useState, useEffect } from "react";
import styles from "./Header.module.css";
import SearchIcon from "../../../img/Search-icon.svg";

export default function LoggedOutHeaderSearch() {
	const [searchBarOpen, setSearchBarOpen] = useState(false);
	const [searchStr, setSearchStr] = useState("");

	const node = useRef();
	const input = useRef();

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (searchStr.length !== 0) {
			if (searchStr !== " ") {
				let replaceSpaces = searchStr.replace(/\s/g, "+");
				window.location = `/search?q=${replaceSpaces}`;
			} else {
				window.location = `/search?q=`;
			}
		}
	};

	useEffect(() => {
		// add when mounted
		document.addEventListener("mousedown", handleClick);
		// return function to be called when unmounted
		return () => {
			document.removeEventListener("mousedown", handleClick);
		};
	}, []);

	// Closes the searchbar when a click is made outside of it
	const handleClick = (e) => {
		if (node.current && node.current.contains(e.target)) {
			// inside click
			return;
		} // outside click
		setSearchBarOpen(false);
	};
	const openSearchBar = () => {
		setSearchBarOpen(true);
	};

	return (
		<div
			tabIndex="3"
			ref={node}
			className={styles.loggedOutHeaderSearch}
			onClick={openSearchBar}
		>
			<div className={styles.headerSearchIcon}>
				<img src={SearchIcon} alt="Search Icon" />
			</div>

			<form
				onSubmit={handleSubmit}
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<input
					style={
						searchBarOpen
							? { width: "350px", opacity: 1, marginLeft: "-18px" }
							: { width: "0px", opacity: 0, marginLeft: "0", paddingLeft: 0 }
					}
					ref={(input) => input && input.focus()}
					type="text"
					placeholder="SEARCH FOR A GAME OR FACILITY"
					value={searchStr}
					onChange={(e) => setSearchStr(e.target.value)}
					maxLength="180"
					data-testid="searchInputNav"
				/>
				<button type="submit" data-testid="navSearch">SEARCH</button>
			</form>
		</div>
	);
}
