import React, { useState, useEffect, useRef } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import styles from "../Auth.module.css";
import PaymentForm from "./PaymentForm";
import SubscriptionSelect from "./SubscriptionSelect";
import PaymentConfirmation from "./PaymentConfirmation";

import { useDispatch, useSelector } from "react-redux";
import { setCurrentModal, closeModal, setErrorData } from "../../../../redux/actions/modal";
import { Auth } from "aws-amplify";
import Spinner from "../../../general/spinner/Spinner";
import ReactGA from "react-ga4";
// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.

export default function App() {
	const dispatch = useDispatch();
	const paymentSucceeded = useSelector((state) => state.user.paymentSucceeded);
	const [userData, setUserData] = useState(null);
	const [clientSecret, setClientSecret] = useState("");
	const [selectedSubscriptionID, setSelectedSubscriptionID] = useState(null);
	const [selectedSubscriptionTitle, setSelectedSubscriptionTitle] = useState(null);
	const [displayMessage, setDisplayMessage] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [products, setProducts] = useState(null);
	const [apiKey, setApiKey] = useState(null);
	const [stripePromise, setStripePromise] = useState(null);

	useEffect(() => {
		// Load the Stripe library once the api key is retrieved
		if (apiKey !== null) {
			setStripePromise(loadStripe(apiKey));
		}
	}, [apiKey]);

	useEffect(() => {
		// ReactGA.pageview("payment_modal");
		authenticateUser();
	}, []);

	useEffect(() => {
		createPaymentIntent();
	}, [selectedSubscriptionID]);

	let authenticateUser = async () => {
		setIsLoading(true);
		await Auth.currentAuthenticatedUser({ bypassCache: true })
			.then((user) => {
				setUserData(user);
				let passExpiration = new Date(user.attributes["custom:passEnd"]);
				//  Check to see if user already has a valid access pass.
				//  They shouldnt ever be able to navigate to this modal
				//  if they already have a valid pass...in theory. This is just a safety net.
				if (passExpiration > new Date()) {
					setDisplayMessage(
						"Our records show that you already have a valid access pass. If this is a mistake, please contact our tech support at support@muscovision.com."
					);
					setIsLoading(false);
				} else {
					// Get subscription data and save it to state.
					// Also gets the api key thats used to load the Stripe library

					const getSubscriptions = async () => {
						await fetch(`https://royia0wuq3.execute-api.us-east-2.amazonaws.com/prod/payment/passes`, {
							headers: {
								Authorization: user.signInUserSession.idToken.jwtToken,
							},
						})
							.then((response) => {
								if (response.status !== 200) {
									throw new Error();
								} else {
									return response.text();
								}
							})
							.then((res) => {
								const data = JSON.parse(res);
								setApiKey(data.api_key);

								let subscriptions = data.products;
								console.log(subscriptions);
								setProducts(subscriptions);
							})
							.catch((err) => {
								dispatch(
									setErrorData({
										message: "An unexpected error occurred",
									})
								);
								dispatch(setCurrentModal("error"));
							});
						setIsLoading(false);
					};
					getSubscriptions();
				}
			})

			.catch((err) => {
				// User is not signed in, redirect to login modal
				dispatch(setCurrentModal("login"));
			});
	};
	let createPaymentIntent = async () => {
		// Called in useEffect when subscription is selected.
		// Create payment intent when a subscription (other than "free") is selected.
		if (selectedSubscriptionID !== null && selectedSubscriptionID !== "free") {
			setIsLoading(true);
			await fetch("https://royia0wuq3.execute-api.us-east-2.amazonaws.com/prod/payment/create", {
				method: "POST",
				headers: {
					Authorization: userData.signInUserSession.idToken.jwtToken,
				},
				body: JSON.stringify({
					paymentType: ["card"],
					description: `MuscoVision Access Pass - Web - ${selectedSubscriptionTitle}`,

					metadata: {
						userID: userData.username,
						userEmail: userData.attributes.email,
						passID: selectedSubscriptionID,
					},
				}),
			})
				.then((res) => res.json())
				.then((data) => {
					setClientSecret(data.client_secret);
				})
				.catch((err) => {
					dispatch(
						setErrorData({
							message: "An unexpected error occured",
						})
					);
					dispatch(setCurrentModal("error"));
				});
			setIsLoading(false);
		}
	};

	const appearance = {
		theme: "stripe",
	};
	const options = {
		clientSecret,
		appearance,
	};

	return (
		<div className={styles.formWrapper}>
			{paymentSucceeded ? (
				// Render confirmation modal. Send the selected pass.
				<PaymentConfirmation selectedSubscription={products.find((i) => i.passID === selectedSubscriptionID)} />
			) : (
				<div className={styles.paymentForm}>
					{isLoading ? (
						<Spinner color="0, 93, 164" />
					) : (
						<>
							{!displayMessage ? (
								<>
									{products !== null && products ? (
										<SubscriptionSelect
											subscriptions={products}
											selectedValue={selectedSubscriptionID}
											setSubscription={(id, title) => {
												setSelectedSubscriptionID(id);
												setSelectedSubscriptionTitle(title);
											}}
										/>
									) : null}

									{clientSecret && selectedSubscriptionID && selectedSubscriptionID !== "free" ? (
										<Elements options={options} stripe={stripePromise}>
											<PaymentForm clientSecret={clientSecret} />
										</Elements>
									) : selectedSubscriptionID === "free" ? (
										<button className={styles.submitBtn} onClick={() => dispatch(closeModal())}>
											CONFIRM
										</button>
									) : null}
								</>
							) : (
								<p style={{ color: "red" }}>{displayMessage}</p>
							)}
						</>
					)}
				</div>
			)}
		</div>
	);
}
