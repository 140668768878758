import React, { useEffect } from "react";

// Styles
import "./App.css";

// Utilities
import Authentication from "./components/utils/Authentication";
import ScrollToTop from "./components/utils/ScrollToTop";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setScreenWidth } from "./redux/actions/general";
import ReactGA from "react-ga4";
import PrivateRoute from "./containers/private/PrivateRoute";
import PublicOnlyRoute from "./containers/public/PublicOnlyRoute";

// Public routes

import Home from "./containers/public/home/Home";
import Search from "./containers/public/search/Search";
import Facilities from "./containers/public/facilities/Facilities";
import Broadcast from "./containers/public/broadcast/Broadcast";

import Facility from "./containers/public/facility/Facility";
import Nearby from "./containers/public/nearby/Nearby";
import PrivacyPolicy from "./containers/public/privacyPolicy/PrivacyPolicy";
import NotFoundPage from "./containers/public/notFoundPage/NotFoundPage";
import Register from "./containers/public/auth/Register";
import Login from "./containers/public/auth/Login";

// Protected routes
import HomeProtected from "./containers/private/home/Home";
import Payment from "./containers/private/payment";

// Components
import Header from "./components/layout/header/Header";
import SideDrawer from "./components/layout/sideDrawer/SideDrawer";
import Backdrop from "./components/layout/backdrop/Backdrop";
import Footer from "./components/layout/footer/Footer";
import Modal from "./components/modal/Modal";
import MobileNav from "./components/layout/mobileNav/MobileNav";

const App = () => {
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
	const sideDrawerOpen = useSelector((state) => state.general.sideDrawerOpen);
	const dispatch = useDispatch();

	useEffect(() => {
		// Start listening for window resize
		window.addEventListener("resize", updateWindowDimensions);
		ReactGA.initialize("G-D91V6KBM2P");
		// Remove event listener on unmount
		return () => {
			window.removeEventListener("resize", () => {});
		};
	}, []);

	// Save screen width to state
	const updateWindowDimensions = () => {
		dispatch(setScreenWidth(window.innerWidth));
	};

	return (
		<Router>
			<Authentication>
				<ScrollToTop>
					{!window.location.pathname.includes("broadcast-widget") ? (
						<>
							<Header />
							<SideDrawer />
							<MobileNav />
						</>
					) : null}

					<Backdrop />
					<Modal />
					<main className={sideDrawerOpen ? "blur" : null}>
						<Switch>
							<PrivateRoute path="/pay" component={Payment} />
							<Route path="/register" component={Register} />
							<PublicOnlyRoute path="/login" component={Login} />
							<Route path="/nearby" component={Nearby} />
							<Route path="/privacy-policy" component={PrivacyPolicy} />
							<Route path="/facility/:siteId" component={Facility} />
							<Route path="/broadcast/:broadcastId" component={Broadcast} />

							<Route path="/search" component={Search} />
							<Route path="/facilities" component={Facilities} />
							<Route path="/privacy_policy" component={PrivacyPolicy} />
							<Route exact path="/" component={!isAuthenticated ? Home : HomeProtected} />
							<Route path="/error/:error?" component={NotFoundPage} />
							<Route path="*" component={NotFoundPage} />
						</Switch>
					</main>
					{!window.location.pathname.includes("broadcast-widget") ? <Footer /> : null}
				</ScrollToTop>
			</Authentication>
		</Router>
	);
};

export default App;
