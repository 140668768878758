import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SideDrawerToggler from "../sideDrawer/SideDrawerToggler";
import { useSelector, useDispatch } from "react-redux";

import MVLogo from "../../../img/MuscoVision_with-tag-white-01.svg";
import FinderIcon from "../../../img/Finder-icon-white.svg";

import SearchBar from "../../filters/SearchBar";
import LoggedOutHeaderSearch from "./LoggedOutHeaderSearch";
import styles from "./Header.module.css";
import { withRouter, useHistory } from "react-router-dom";
import { toggleLogin } from "../../../redux/actions/auth";
import { setCurrentModal } from "../../../redux/actions/modal";

import { Auth } from "aws-amplify";

const Header = (props) => {
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
	const sideDrawerOpen = useSelector((state) => state.general.sideDrawerOpen);
	const [userName, setUserName] = useState(null);
	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		Auth.currentAuthenticatedUser()
			.then((user) => {
				setUserName(user.attributes.email);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);
	const loggedInHeader = () => {
		return (
			<React.Fragment>
				<div className={styles.loggedInHeaderSearch}>
					<SearchBar placeholder="SEARCH FOR A GAME OR FACILITY" />
				</div>
				<Link to="/nearby" className={styles.gamesNearbyLink}>
					<span>
						<img className={styles.nearbyIcon} src={FinderIcon} alt="Games nearby" />
					</span>
					<span className={styles.nearbyText} data-testid="gamesNearby">
						GAMES NEARBY
					</span>
				</Link>
				{userName ? (
					<button className={styles.userProfileLink}>
						{/* <div className={styles.avatar}></div> */}
						<span>{userName}</span>
						<div className={styles.accountActions}>
							<button onClick={() => dispatch(setCurrentModal("profile"))} data-testid="actBtn">
								My Account
							</button>

							<button
								data-testid="logoutBtn"
								onClick={async () => {
									await Auth.signOut().then(() => {
										dispatch(toggleLogin(false));
										history.go(0);
									});
								}}
							>
								Sign Out
							</button>
						</div>
					</button>
				) : null}
			</React.Fragment>
		);
	};

	const loggedOutHeader = () => {
		return (
			<React.Fragment>
				<LoggedOutHeaderSearch />
				<div className={styles.headerLogButtons}>
					<button type="button" className={styles.headerSignup} onClick={() => dispatch(setCurrentModal("register"))}>
						Sign Up
					</button>
					<button type="button" className={styles.headerSignin} onClick={() => dispatch(setCurrentModal("login"))}>
						Sign In
					</button>
				</div>
			</React.Fragment>
		);
	};

	return (
		<header
			className={`${styles.header} ${sideDrawerOpen ? styles.blur : null}`}
			style={
				isAuthenticated
					? { background: "rgb(25,26,28)", background: "linear-gradient(135deg, #005da4 0%, #003c69 100%)" }
					: { backgroundColor: "transparent" }
			}
		>
			<div className={styles.headerLeft}>
				{/* {isAuthenticated ? ( */}
				<div className={styles.sideDrawerToggler}>
					<SideDrawerToggler color="white" />
				</div>
				{/* ) : null} */}

				<Link to="/" style={{ textDecoration: "none" }} tabIndex="2">
					<img className={styles.headerLogo} src={MVLogo} alt="Musco Vision Logo" />
				</Link>
			</div>

			<div className={styles.headerRight}>{isAuthenticated ? loggedInHeader() : loggedOutHeader()}</div>
		</header>
	);
};
export default withRouter(Header);
