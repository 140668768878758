import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";

import { PersistGate } from "redux-persist/integration/react";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./redux/configureStore";

// import Amplify from "aws-amplify";
// import config from "./aws-exports";
import { Amplify } from "aws-amplify";
import config from "./aws-exports";

const { store, persistor } = configureStore();

Amplify.configure(config);

ReactDOM.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<App />
		</PersistGate>
	</Provider>,
	document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
