import React, { useRef, useState, useEffect } from "react";

import styles from "./ActionBtn.module.css";
import Dotz from "../../../../../img/dots-horizontal.png";

export default function MoreActions() {
	const node = useRef();
	const [isOpen, setIsOpen] = useState(false);
	const [display, setDisplay] = useState("none");

	useEffect(() => {
		// add when mounted
		document.addEventListener("mousedown", handleClick);
		// return function to be called when unmounted
		return () => {
			document.removeEventListener("mousedown", handleClick);
		};
	}, []);

	// Closes the modal when a click is made outside of it
	const handleClick = (e) => {
		if (node.current && node.current.contains(e.target)) {
			// inside click
			return;
		} // outside click
		setIsOpen(false);
	};

	useEffect(() => {
		isOpen === true ? setDisplay("block") : setDisplay("none");
	}, [isOpen]);

	return (
		<div className={styles.moreActions}>
			<button onClick={() => setIsOpen(!isOpen)}>
				<img className={styles.icon} src={Dotz} alt="Actions" />
			</button>
			<div
				className={styles.optionsList}
				style={{ display: display }}
				ref={node}
			>
				<ul>
					<li>
						<a href="mailto:feedback@muscovision.com">Provide Feedback</a>
					</li>
					<li>
						<a href="mailto:support@muscovision.com">Contact Support</a>
					</li>
				</ul>
			</div>
		</div>
	);
}
