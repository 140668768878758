import React, { useState, useEffect } from "react";

import { Auth } from "aws-amplify";
import Spinner from "../../components/general/spinner/Spinner";
import { Route, Redirect } from "react-router-dom";
const PrivateRoute = ({ path, component: Component, ...rest }) => {
	const [loading, setLoading] = useState(true);
	const [user, setUser] = useState(null);
	useEffect(() => {
		authenticateUser();
	}, []);

	let authenticateUser = async () => {
		await Auth.currentAuthenticatedUser({ bypassCache: true })
			.then((user) => {
				setUser(user);
			})
			.catch((err) => {
				setUser(null);
			});
		setLoading(false);
	};
	if (loading) {
		return (
			<div style={spinnerWrapper}>
				<Spinner color="250, 250, 250" />
			</div>
		);
	}
	return (
		<Route
			{...rest}
			render={(props) =>
				user ? (
					<Component user={user} {...props} />
				) : (
					<Redirect
						to={{
							pathname: "/login",
							state: { redirectAfterLogin: path },
						}}
					/>
				)
			}
		/>
	);
};
const spinnerWrapper = {
	display: "flex",
	height: "70vh",
	justifyContent: "center",
	alignItems: "center",
};

export default PrivateRoute;
