import React from "react";
import Slider from "react-slick";
import "./style.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useHistory } from "react-router-dom";

const MobileList = ({ broadcasts, isLoading }) => {
	let history = useHistory();
	var settings = {
		dots: false,
		infinite: true,
		speed: 200,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		centerMode: true,
		draggable: true,
	};
	const onBroadcastClick = (siteId, broadcastId) => {
		history.push(`/broadcast/${broadcastId}`);
	};

	return (
		<div className="mobileMediaList">
			<Slider {...settings}>
				{broadcasts.length !== 0
					? broadcasts.map((item, index) => (
							<div onClick={() => onBroadcastClick(item.site_id, item.id)}>
								<img
									width="100%"
									style={{ maxHeight: "200px" }}
									src={item.medium_image}
									alt={item.title + " thumbnail"}
								/>
								<h6 className="itemTitle" style={{ color: "white" }}>
									{item.title}
								</h6>
							</div>
					  ))
					: null}
			</Slider>
		</div>
	);
};
export default MobileList;
