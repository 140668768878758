import React from "react";
import styles from "./Payment.module.css";
import { Link } from "react-router-dom";
function SubscriptionSelect({ selectedValue, setSubscription, subscriptions }) {
	console.log(selectedValue);
	return (
		<div className={styles.subscriptionSelect}>
			{selectedValue ? (
				<div className={styles.subscriptionInfoCard}>
					<div>
						<h3 className={styles.passName}>{selectedValue.name}</h3>
						<p className={styles.passDesc}>{selectedValue.description}</p>
						<span className={styles.price}>{`${"$" + selectedValue.amount / 100}`}</span>
					</div>
					<button className={styles.changeSubBtn} onClick={() => setSubscription(null)}>
						Change
					</button>
				</div>
			) : (
				<>
					<h3 className={styles.subscriptionSelectTitle}>PICK YOUR PASS</h3>
					<div className={styles.cardContainer}>
						{subscriptions.map((sub, i) => (
							<button type="button" className={styles.subscriptionCard} onClick={() => setSubscription(sub)}>
								<div>
									<span className={styles.passName}>{sub.name}</span>
									<span className={styles.passDesc}>{sub.description}</span>
								</div>
								<div style={{ width: "100%" }}>
									<span className={styles.price}>{`${"$" + sub.amount / 100}`}</span>
								</div>
							</button>
						))}
					</div>
					<Link className={styles.skipLink} to="/">
						Skip for now
					</Link>
				</>
			)}
		</div>
	);
}

export default SubscriptionSelect;
