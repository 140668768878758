import React from "react";
import styles from "./Footer.module.css";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useSelector } from "react-redux";
import MVLogo2 from "../../../img/MV-BTYB-ML_300dpi.png";
import MVLogo1 from "../../../img/MuscoVision_with-tag-white-01.svg";
import FacebookIcon from "../../../img/facebook-icon.svg";
import InstagramIcon from "../../../img/instagram-icon.svg";
import TwitterIcon from "../../../img/twitter-icon.svg";
import YoutubeIcon from "../../../img/youtube-icon.svg";

export default function Footer() {
	// const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
	return (
		<footer className={styles.footer}>
			<div className={styles.left}>
				<img className={styles.footerLogo2} alt="Musco Lighting Logo" src={MVLogo2} />
				<img className={styles.footerLogo1Mobile} src={MVLogo1} alt="Musco Vision Logo" />
				<p className={styles.support}>
					<strong>Contact for support: </strong>
					<br />
					<a href="tel:8444340587"> (844) 434-0587</a> <br />
					<a href="mailto:support@muscovision.com"> support@muscovision.com</a>
					<br />
					<br />
					<strong>Give us feedback: </strong>
					<br />
					<a href="mailto:feedback@muscovision.com"> feedback@muscovision.com</a>
				</p>
			</div>
			<div className={styles.middle}>
				<img className={styles.footerLogo1Desktop} src={MVLogo1} alt="Musco Vision Logo" />
				<div className={styles.footerNav}>
					<a href="/docs/terms_of_use.pdf" target="_blank" className={styles.footerNavLink}>
						TERMS OF USE
					</a>
					<span className={styles.footerNavSpacer}></span>
					<a href="/docs/online_privacy_policy.pdf" target="_blank" className={styles.footerNavLink}>
						PRIVACY POLICY
					</a>
					<span className={styles.footerNavSpacer}></span>
					<a href="/docs/online_sale_terms_and_conditions.pdf" target="_blank" className={styles.footerNavLink}>
						SALES TERM AND CONDITIONS
					</a>

					<span className={styles.footerNavSpacer}></span>
					<HashLink smooth to="/#pricing" className={styles.footerNavLink}>
						PRICING
					</HashLink>
				</div>
			</div>
			<div className={styles.right}>
				<div className={styles.footerSocialBlock}>
					<a
						className={styles.footerSocialIcon}
						target="_blank"
						rel="noopener noreferrer"
						href="https://www.facebook.com/muscolighting"
					>
						<img src={FacebookIcon} alt="Facebook Icon" />
					</a>
					<a
						className={styles.footerSocialIcon}
						target="_blank"
						rel="noopener noreferrer"
						href="https://www.instagram.com/muscosportslighting/"
					>
						<img src={InstagramIcon} alt="Instagram Icon" />
					</a>
					<a
						className={styles.footerSocialIcon}
						target="_blank"
						rel="noopener noreferrer"
						href="https://twitter.com/MuscoLighting"
					>
						<img src={TwitterIcon} alt="Twitter Icon" />
					</a>
					<a
						className={styles.footerSocialIcon}
						target="_blank"
						rel="noopener noreferrer"
						href="https://www.youtube.com/user/muscolightingllc/videos"
					>
						<img src={YoutubeIcon} alt="Youtube Icon" />
					</a>
				</div>
				<p className={styles.footerCopy}>
					&copy; {new Date().getFullYear()} Musco Sports Lighting, LLC &mdash; All rights reserved
				</p>
			</div>
		</footer>
	);
}
