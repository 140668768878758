import React from "react";
import styles from "./AccessPrompt.module.css";
import { setCurrentModal } from "../../../../redux/actions/modal";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
export default function AccessPrompt({ message }) {
	const dispatch = useDispatch();
	return (
		<div className={styles.accessPrompt}>
			{message === "No valid pass" ? (
				<>
					<p style={{ marginBottom: "1rem" }}>You will need an access pass to watch this broadcast</p>

					<Link to="/pay">PURCHASE ACCESS</Link>
				</>
			) : message === "User not logged in" ? (
				<button data-testid="accessBtn" onClick={() => dispatch(setCurrentModal("login"))}>
					LOGIN TO ACCESS
				</button>
			) : null}
		</div>
	);
}
