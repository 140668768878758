import React, { useState } from "react";
import GoogleMapReact from "google-map-react";
import Marker from "./Marker";

export default function Map({
	data,
	setSelected,
	currentlySelected,
	mapCenter,
	mapZoom,
	changeBounds,
	reSearch,
	currentLocation,
	showButton,
}) {
	const [c, setC] = useState(null);
	const [z, setZ] = useState(null);
	const [b, setB] = useState(null);
	const getNewBounds = ({ center, zoom, bounds }) => {
		setC(center);
		setZ(zoom);
		setB(bounds);
		changeBounds(bounds, center);
	};
	const triggerResearch = () => {
		reSearch(b, c);
	};
	return (
		<div style={{ height: "700px", width: "100%", position: "relative" }}>
			{showButton ? (
				<button
					style={{
						position: "absolute",
						display: "block",
						top: 20,
						left: 20,
						zIndex: 1,
						background: "#005ea4",
						padding: "0 1.5rem",
						height: 40,
						borderRadius: 20,
						fontFamily: "Erbaum-Light",
						color: "#fff",
						border: "none",
					}}
					onClick={triggerResearch}
				>
					Search this area
				</button>
			) : (
				""
			)}
			<GoogleMapReact
				bootstrapURLKeys={{ key: "AIzaSyBylBGCc38ZXS8uwpEYajuUr4dZELhb-Gs" }}
				defaultCenter={{
					lat: 35.0,
					lng: -84.0,
				}}
				options={{ minZoom: 6 }}
				defaultZoom={6}
				// maxZoom={4}
				zoom={mapZoom}
				center={mapCenter}
				onChange={(params) => getNewBounds(params)}
			>
				{data &&
					data.map((item, index) => {
						let isActive = false;
						if (currentlySelected === item.id) {
							isActive = true;
						}

						return (
							<Marker
								isActive={isActive}
								id={item.id}
								lat={item.lat}
								lng={item.lon}
								text={item.title}
								onChildClick={(id) => setSelected(id)}
							/>
						);
					})}
				{currentLocation !== null ? (
					<div
						lat={currentLocation.lat}
						lng={currentLocation.lng}
						style={{
							width: "20px",
							height: "20px",
							backgroundColor: "red",
							borderRadius: "50%",
						}}
					></div>
				) : null}
			</GoogleMapReact>
		</div>
	);
}
