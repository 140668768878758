import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Auth } from "aws-amplify";
import { toggleLogin } from "../../redux/actions/auth";
import { setUserData } from "../../redux/actions/user";
// Re-validates user on every page change
const Authentication = (props) => {
	const dispatch = useDispatch();
	useEffect(() => {
		let authenticateUser = async () => {
			await Auth.currentAuthenticatedUser()
				.then((user) => {
					dispatch(setUserData(user));
					dispatch(toggleLogin(true));
				})

				.catch((err) => {
					dispatch(toggleLogin(false));
					dispatch(setUserData(null));
				});
		};
		authenticateUser();
	}, [props.location.pathname]);

	return props.children;
};

export default withRouter(Authentication);
