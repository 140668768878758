import React, { useState } from "react";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";

import styles from "../Auth.module.css";
import { useDispatch } from "react-redux";
import PrivacyPolicyPrompt from "./PrivacyPolicyPrompt";
import ReactGA from "react-ga4";
// import { Auth } from "aws-amplify";
import { setPaymentSucceeded } from "../../../../redux/actions/user";
import Spinner from "../../../general/spinner/Spinner";
export default function CheckoutForm({ clientSecret }) {
	const dispatch = useDispatch();
	const stripe = useStripe();
	const elements = useElements();
	const [message, setMessage] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [formIsReady, setFormIsReady] = useState(false);
	// const [privacyPolicyChecked, setPrivacyPolicyChecked] = useState(false);
	// const [checkboxError, setCheckboxError] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!stripe || !elements || isLoading || !formIsReady) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}
		// if (!privacyPolicyChecked) {
		// 	setCheckboxError(true);
		// 	return;
		// } else {
		// 	setCheckboxError(false);
		// }

		setIsLoading(true);

		await stripe
			.confirmPayment({
				elements,
				confirmParams: {
					// Make sure to change this to your payment completion page
					return_url: window.location.href,
				},
				redirect: "if_required",
			})
			.then((res) => {
				ReactGA.event({
					category: "Payment",
					action: "payment_success",
					label: "Payment Success", // optional
				});
				let { error } = res;
				let { paymentIntent } = res;
				if (error) {
					console.log(error.message);
					setMessage(error.message);

					return;
				} else if (paymentIntent) {
					stripe
						.retrievePaymentIntent(clientSecret)
						.then(({ paymentIntent }) => {
							switch (paymentIntent.status) {
								case "succeeded":
									setMessage("Payment succeeded.");
									dispatch(setPaymentSucceeded(true));
									break;
								case "processing":
									setMessage(
										"Your payment is processing. You will recieve a confirmation email once the transaction is successful."
									);
									break;
								case "payment_failed":
									setMessage("Your payment was not successful. Please try again.");
									break;
								default:
									setMessage("Something went wrong. Please try again.");
									break;
							}
						})
						.catch((error) => {
							setMessage("An unexpected error occured. Please try again.");
						});
				}
			})
			.catch((error) => {
				console.log("2 ERROR!!!");
				setMessage("An unexpected error occured. Please try again.");
			});
		setIsLoading(false);
	};
	return (
		<>
			<div className={styles.horizontalRule}></div>

			<form id="payment-form" onSubmit={handleSubmit}>
				{/* Show any error or success messages */}
				{message && (
					<div className={styles.errorMessage} style={{ marginBottom: "1rem" }}>
						{message}
					</div>
				)}

				<PaymentElement onReady={() => setFormIsReady(true)} id="payment-element" className={styles.paymentElement} />

				{/* <PrivacyPolicyPrompt
							error={checkboxError}
							onCheck={(bool) => setPrivacyPolicyChecked(bool)}
							isChecked={privacyPolicyChecked}
						/> */}
				<button disabled={isLoading || !stripe || !elements || !formIsReady} className={styles.submitBtn}>
					{isLoading ? "PROCESSING..." : "CONFIRM PAYMENT"}
				</button>
			</form>
		</>
	);
}
