import {
	TOGGLE_SIDEDRAWER,
	TOGGLE_FILTERMENU,
	SET_SIDEDRAWER_STATE,
	SET_SCREEN_WIDTH,
} from "../actionTypes";

const initialState = {
	total: 0,
	sideDrawerOpen: false,
	filterMenuOpen: false,
	screenWidth: window.innerWidth,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case TOGGLE_SIDEDRAWER: {
			return {
				...state,
				sideDrawerOpen: !state.sideDrawerOpen,
			};
		}
		case SET_SIDEDRAWER_STATE: {
			return {
				...state,
				sideDrawerOpen: action.payload,
			};
		}
		case TOGGLE_FILTERMENU: {
			return {
				...state,
				filterMenuOpen: !state.filterMenuOpen,
			};
		}
		case SET_SCREEN_WIDTH: {
			return {
				...state,
				screenWidth: action.payload,
			};
		}

		default:
			return state;
	}
}
