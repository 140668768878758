import React from "react";
import { Link } from "react-router-dom";
import SideDrawerToggler from "./SideDrawerToggler";
import Nav from "./Nav";
import { useSelector, useDispatch } from "react-redux";
import { setSideDrawerState } from "../../../redux/actions/general";
import MVLogoBlue from "../../../img/MuscoVision_logo-blue-grey.svg";
import MVLogoWhite from "../../../img/MuscoVision_white.svg";
import CloseBtn from "../../../img/X-symbol-white.svg";
import styles from "./SideDrawer.module.css";
var pjson = require("../../../../package.json");
const SideDrawer = () => {
	const dispatch = useDispatch();
	const sideDrawerOpen = useSelector((state) => state.general.sideDrawerOpen);
	const screenWidth = useSelector((state) => state.general.screenWidth);

	return (
		<div
			className={`${styles.sideDrawer} ${sideDrawerOpen ? styles.open : null}`}
		>
			<div className={styles.sideDrawerHead}>
				{screenWidth > 1024 ? <SideDrawerToggler color="black" /> : null}
				<Link to="/" style={{ textDecoration: "none" }}>
					{screenWidth < 1024 ? (
						<img src={MVLogoWhite} alt="MuscoVision Logo" />
					) : (
						<img width="162" src={MVLogoBlue} alt="MuscoVision Logo" />
					)}
				</Link>
				{screenWidth < 1024 ? (
					<button
						className={styles.mobileMenuCloseBtn}
						onClick={() => dispatch(setSideDrawerState(false))}
					>
						<img src={CloseBtn} alt="Close" />
					</button>
				) : null}
			</div>

			<Nav />
			<p className={styles.versionText}>v{pjson.version}</p>
		</div>
	);
};

export default SideDrawer;
