import React, { useRef, useEffect } from "react";

export default function useEffectSkipFirst(fn, arr) {
	const isFirst = useRef(true);

	useEffect(() => {
		if (isFirst.current) {
			isFirst.current = false;
			return;
		}

		return fn();
	}, arr);
}
