import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import styles from "./Search.module.css";
import Filters from "./filters/Filters";
import { useSelector } from "react-redux";
import FindAGame from "./FindAGame";

import ReactGA from "react-ga4";

import GetFacilities from "../../../components/data/getFacilities/GetFacilities";
import GetBroadcasts from "../../../components/data/getBroadcasts/GetBroadcasts";
import CloseBtn from "../../../img/close-btn-icon.svg";
import FilterIcon from "../../../img/Filter-icon-white.svg";
function Search(props) {
	const screenWidth = useSelector((state) => state.general.screenWidth);

	const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
	const [filtersSet, setFiltersSet] = useState(false);
	const [renderSearchBarScreen, setRenderSearchBarScreen] = useState(false);
	const [filters, setFilters] = useState({
		dates: {
			startDate: "",
			endDate: "",
		},
		sports: [],
		searchQuery: null,
	});

	//GA page view
	useEffect(() => {
		// ReactGA.pageview(window.location.pathname + window.location.search);
		const queryString = window.location.search;

		if (queryString === "") {
			setRenderSearchBarScreen(true);
		}
	}, []);

	const renderToggleMenuBtn = () => {
		if (screenWidth < 900) {
			if (mobileFiltersOpen) {
				return (
					<button onClick={() => setMobileFiltersOpen(false)} className={styles.filtersCloseBtn}>
						<img src={CloseBtn} alt="Close Button" />
					</button>
				);
			} else
				return (
					<button
						onClick={() => {
							setMobileFiltersOpen(true);
						}}
						className={styles.filtersOpenBtn}
					>
						<img src={FilterIcon} alt="Filter Icon" />
					</button>
				);
		}
	};

	return (
		<div className={styles.searchResults}>
			{!renderSearchBarScreen ? (
				<React.Fragment>
					{filters.searchQuery !== null ? (
						<section className={styles.header}>
							<h1>SEARCH RESULTS FOR '{filters.searchQuery}'</h1>
						</section>
					) : null}
					{renderToggleMenuBtn()}

					<div className={styles.mainRow}>
						<Filters
							parentProps={props}
							mobileFiltersOpen={mobileFiltersOpen}
							setMobileFiltersOpen={(x) => setMobileFiltersOpen(x)}
							setFilters={(x) => {
								setFilters(x);

								setFiltersSet(true);
							}}
						/>
						{filtersSet ? (
							<div className={styles.content}>
								<section className={styles.contentSection}>
									<GetFacilities
										searchQuery={filters.searchQuery}
										isLoading={filters.searchQuery === null}
										numOfItemsDefault={6}
										numOfItemsToIncrement={6}
									/>
								</section>
								{/* live broadcasts */}
								<section className={styles.contentSection}>
									<GetBroadcasts
										facilityID={""}
										updateParent={() => null}
										isLoading={filters.searchQuery === null}
										filters={filters}
										viewerStatus={"streaming"}
									/>
								</section>
								{/* upcoming broadcasts */}
								<section className={styles.contentSection}>
									<GetBroadcasts
										facilityID={""}
										updateParent={() => null}
										isLoading={filters.searchQuery === null}
										filters={filters}
										viewerStatus={"scheduled"}
									/>
								</section>

								{/* archived broadcasts */}
								<section className={styles.contentSection}>
									<GetBroadcasts
										facilityID={""}
										updateParent={() => null}
										isLoading={filters.searchQuery === null}
										filters={filters}
										viewerStatus={"archived"}
									/>
								</section>
							</div>
						) : null}
					</div>
				</React.Fragment>
			) : (
				<FindAGame />
			)}
		</div>
	);
}
export default withRouter(Search);
