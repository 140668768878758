import React from "react";
import { useDispatch } from "react-redux";
import { toggleSideDrawer } from "../../../redux/actions/general";
import styles from "./SideDrawer.module.css";

export default function SideDrawerToggler(props) {
	const dispatch = useDispatch();

	return (
		<div
			className={styles.sideDrawerToggler}
			onClick={() => dispatch(toggleSideDrawer())}
		>
			<svg
				width="30px"
				height="23px"
				viewBox="0 0 38 23"
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g id="Hamburger" transform="translate(1.25 1.25)">
					<path
						d="M0.5 0.5L34.5 0.5"
						transform="translate(0 9.5)"
						id="Line-5-Copy"
						fill="none"
						stroke={props.color}
						strokeWidth="2.5"
						strokeLinecap="round"
					/>
					<path
						d="M0.5 0.5L34.5 0.5"
						transform="translate(0 19)"
						id="Line-5-Copy-2"
						fill="none"
						stroke={props.color}
						strokeWidth="2.5"
						strokeLinecap="round"
					/>
					<path
						d="M0.5 0.5L34.5 0.5"
						id="Line-5"
						fill="none"
						stroke={props.color}
						strokeWidth="2.5"
						strokeLinecap="round"
					/>
				</g>
			</svg>
		</div>
	);
}
