import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Auth } from "aws-amplify";
import { setCurrentModal, setLoading, closeModal } from "../../../redux/actions/modal";
import { useHistory } from "react-router-dom";
import { setUserToConfirm } from "../../../redux/actions/auth";
import logo from "../../../img/MuscoVision_logo-blue-grey.svg";
import styles from "./Auth.module.css";
import { toggleLogin } from "../../../redux/actions/auth";
import ReactGA from "react-ga4";

export default function Login() {
	const dispatch = useDispatch();
	const history = useHistory();
	const [errorMessage, setErrorMessage] = useState(null);
	const [successMessage, setSuccessMessage] = useState(null);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [fieldErrors, setFieldErrors] = useState({
		email: null,
		password: null,
	});
	useEffect(() => {
		// ReactGA.pageview("login_modal");
	}, []);
	const handleSubmit = (e) => {
		e.preventDefault();
		setSuccessMessage(null);
		setErrorMessage(null);
		setFieldErrors({
			email: null,
			password: null,
		});

		const validateFields = new Promise((resolve, reject) => {
			let errors = {
				email: null,
				password: null,
			};
			let errorFound = false;
			if (email === "") {
				errorFound = true;
				errors.email = "Please enter your email address";
			} else {
				// Validate the email with a regex
				const res =
					/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				if (!res.test(String(email).toLowerCase())) {
					errorFound = true;
					errors.email = "Email is invalid";
				}
			}

			if (password === "") {
				errorFound = true;
				errors.password = "Please enter your password";
			}

			if (!errorFound) {
				resolve();
			} else reject(errors);
		});
		validateFields
			.then(() => {
				console.log("resolved");
				dispatch(setLoading({ isLoading: true, message: "Logging in..." }));
				signIn();
			})
			.catch((err) => {
				console.log(err);
				setFieldErrors(err);
			});
	};

	const signIn = async () => {
		await Auth.signIn(email, password)

			.then((res) => {
				dispatch(setLoading({ isLoading: false, message: "" }));
				dispatch(toggleLogin(true));
				dispatch(closeModal());
				ReactGA.event({
					category: "Auth Events",
					action: "login",
					label: "Login", // optional
				});
				history.go(0);
			})

			.catch((err) => {
				// console.log(JSON.parse(JSON.stringify(err)));
				dispatch(setLoading({ isLoading: false, message: "" }));
				switch (err.code) {
					case "PasswordResetRequiredException":
						dispatch(setCurrentModal("reset_password"));
						break;
				}
				switch (err.message) {
					case "User is not confirmed.":
						// Resend Confirmation code and change to confirmation code modal
						async function resendConfirmationCode() {
							try {
								await Auth.resendSignUp(email);

								dispatch(setUserToConfirm({ email: email, password: password }));
								dispatch(setCurrentModal("confirm_account"));
							} catch (error) {
								setErrorMessage("There was a problem logging you in.");
							}
						}
						resendConfirmationCode();
						break;
					case "User does not exist.":
						setErrorMessage("User does not exist.");

						break;
					case "Attempt limit exceeded, please try after some time.":
						setErrorMessage("Attempt limit exceeded, please try again later.");
						break;
					case "Incorrect username or password.":
						setErrorMessage("Incorrect username or password.");
						break;
					// case "NotAuthorizedException":
					// 	setErrorMessage("Login credentials are invalid. Please try again.");
					// 	break;
					// case "UserNotFoundException":
					// 	setErrorMessage(
					// 		"The email you have entered does not exist in our records."
					// 	);
					// 	break;
					default:
						setErrorMessage("There was a problem logging you in.");
				}
			});
	};

	return (
		<div className={styles.formWrapper}>
			<img alt="logo" style={{ marginBottom: "3rem" }} src={logo} />
			<h3 style={{ color: "black", marginBottom: "1.5rem", textAlign: "center" }}>SIGN IN TO YOUR ACCOUNT</h3>

			{successMessage ? <span className={styles.successMessage}>{successMessage}</span> : null}
			{errorMessage ? <span className={styles.errorMessage}>{errorMessage}</span> : null}
			<form className={styles.loginForm} onSubmit={handleSubmit} autocomplete="on">
				<div className={styles.inputGroup}>
					<label className={styles.label}>EMAIL</label>
					<input
						autoFocus
						type="email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						placeholder="EMAIL"
						autocomplete="on"
					/>
					{fieldErrors.email !== null ? <span className={styles.errorMessage}>{fieldErrors.email}</span> : null}
				</div>
				<div className={styles.inputGroup}>
					<label className={styles.label}>PASSWORD</label>
					<input
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						type="password"
						placeholder="PASSWORD"
						autocomplete="on"
					/>
					{fieldErrors.password !== null ? <span className={styles.errorMessage}>{fieldErrors.password}</span> : null}
				</div>
				<button
					type="button"
					onClick={() => dispatch(setCurrentModal("reset_password"))}
					className={styles.forgotPasswordBtn}
				>
					Forgot password?
				</button>

				<button className={styles.submitBtn} type="submit">
					LOG IN
				</button>
			</form>

			<p style={{ color: "black", marginTop: "3rem" }}>Don't have an account?</p>
			<button className={styles.switchToSignUpBtn} onClick={() => dispatch(setCurrentModal("register"))}>
				SIGN UP
			</button>
		</div>
	);
}
