import React, { useState, useEffect, useRef } from "react";

import styles from "./Filters.module.css";

export default function SportFilter({ onChange, selected }) {
	return (
		<div className={styles.sportFilter}>
			<span className={styles.filterHeader}>REFINE BY SPORT</span>
			<label className={styles.container}>
				<input
					data-testid="checkbox-1"
					type="checkbox"
					checked={selected.baseball}
					onChange={() =>
						onChange({
							...selected,
							baseball: !selected.baseball,
						})
					}
				/>
				<span className={styles.checkmark}></span>
				Baseball
			</label>
			<label className={styles.container}>
				<input
					data-testid="checkbox-2"
					type="checkbox"
					checked={selected.football}
					onChange={() =>
						onChange({ ...selected, football: !selected.football })
					}
				/>
				<span className={styles.checkmark}></span>
				Football
			</label>
			<label className={styles.container}>
				<input
					data-testid="checkbox-3"
					type="checkbox"
					checked={selected.soccer}
					onChange={() => onChange({ ...selected, soccer: !selected.soccer })}
				/>
				<span className={styles.checkmark}></span>
				Soccer
			</label>
			<label className={styles.container}>
				<input
					data-testid="checkbox-4"
					type="checkbox"
					checked={selected.softball}
					onChange={() =>
						onChange({ ...selected, softball: !selected.softball })
					}
				/>
				<span className={styles.checkmark}></span>
				Softball
			</label>
			<label className={styles.container}>
				<input
					data-testid="checkbox-5"
					type="checkbox"
					checked={selected.basketball}
					onChange={() =>
						onChange({ ...selected, basketball: !selected.basketball })
					}
				/>
				<span className={styles.checkmark}></span>
				Basketball
			</label>
		</div>
	);
}
