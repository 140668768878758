import React, { useEffect } from "react";
import styles from "./NotFoundPage.module.css";
import SearchBar from "../../../components/filters/SearchBar";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";
import NearbyIcon from "../../../img//nearby-link-icon.svg";
const NotFoundPage = (props) => {
	//GA page view
	useEffect(() => {
		// ReactGA.pageview(window.location.pathname + window.location.search);
	}, []);

	let errorMsg, errorTitle;
	if (props.match.path != "*" && props.match.params.error != undefined) {
		switch (props.match.params.error.toLowerCase()) {
			case "facility-not-found":
				errorTitle = "FACILITY NOT FOUND";
				errorMsg = "We couldn't find the facility you were looking for.";
				break;
			case "broadcast-not-found":
				errorTitle = "BROADCAST NOT FOUND";
				errorMsg = "We couldn't find the broadcast you were looking for.";
				break;
			default:
				errorTitle = "PAGE NOT FOUND";
				errorMsg = "We couldn't find the page you were looking for.";
		}
	} else {
		errorTitle = "PAGE NOT FOUND";
		errorMsg = "We couldn't find the page you were looking for.";
	}

	return (
		<section className={styles.find}>
			<h1 className={styles.heading}>{errorTitle}</h1>
			<br />
			<p className={styles.subheading}>{errorMsg}</p>

			<p>Try searching for a broadcast or facility.</p>
			<div className={styles.searchBar}>
				<SearchBar placeholder="SEARCH" />
			</div>

			<Link to="/nearby" className={styles.gamesNearMeLink}>
				<img style={{ marginRight: ".5rem" }} src={NearbyIcon} alt="Games Near Me Icon" />
				FIND GAMES NEAR ME
			</Link>
		</section>
	);
};

export default NotFoundPage;
