import React from "react";
import styles from "../Nearby.module.css";
import { Link } from 'react-router-dom';

export default function Marker({ text, isActive, onChildClick, id }) {
	// const [colorOne, setColorOne] = useState(null);
	// const [colorTwo, setColorTwo] = useState(null);

	return (
		<Link to={`/facility/${id}`} className={styles.marker} key={id}> 
			<div className={styles.hoverInfoBox}>{text}</div>
			{isActive ? <div className={styles.activeInfoBox}>{text}</div> : <div className={styles.hoverInfoBox}>{text}</div>}
			<svg width="52px" height="52px" viewBox="0 0 52 52" version="1.1" xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
				
				<defs>
					<linearGradient x1="0" y1="0" x2="1" y2="0.923838556" id="gradient_1">
					<stop offset="0" stop-color="#00427A" />
					<stop offset="1" stop-color="#1DA1F2" />
					</linearGradient>
					<path d="M0 0L29.25 0L29.25 26L0 26L0 0Z"/>
					
				</defs>
				<g transform="translate(0.7197266 0.7200012)">
					<path d="M25.0666 50.1332C38.9105 50.1332 50.1332 38.9105 50.1332 25.0666C50.1332 11.2227 38.9105 0 25.0666 0C11.2227 0 0 11.2227 0 25.0666C0 38.9105 11.2227 50.1332 25.0666 50.1332Z" id="Oval" fill="url(#gradient_1)" stroke="none" />
					<g transform="translate(11.49902 12.52034)">
					<path d="M0 0L29.25 0L29.25 26L0 26L0 0Z" fill="none" stroke="none" />
					<g>
						<path d="M2.42734 0L17.0727 0C18.4133 0 19.5 1.08672 19.5 2.42734L19.5 17.0727C19.5 18.4133 18.4133 19.5 17.0727 19.5L2.42734 19.5C1.08672 19.5 0 18.4133 0 17.0727L0 2.42734C0 1.08672 1.08672 0 2.42734 0ZM20.4619 4.69802L26.0275 0.85896C27.1092 0.117553 28.5869 0.879272 28.5869 2.16912L28.5869 15.2148C28.5869 16.5097 27.1041 17.2664 26.0275 16.525L20.4619 12.691L20.4619 4.69802Z" transform="translate(0 3.25)" id="Shape" fill="#fff" fill-rule="evenodd" stroke="none" />
					</g>
					</g>
				</g>
				</svg>
		</Link>
	);
}
