import React from "react";
import styles from "./Spinner.module.css";

export default function Spinner({ color }) {
	const colorStyles = {
		border: {
			borderTop: `0.5em solid rgb(${color})`,
			borderRight: `0.5em solid rgb(${color})`,
			borderBottom: `0.5em solid rgb(${color})`,
			borderLeft: `0.5em solid rgba(${color},.35)`,
		},
	};
	return <div className={styles.spinner} style={colorStyles.border}></div>;
}
