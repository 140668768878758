import { applyMiddleware, createStore, compose } from "redux";
import rootReducer from "./reducers";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

const initialState = {};
const middleware = [thunk];

const persistConfig = {
	key: "root",
	storage,
	whitelist: [""],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default () => {
	let store = createStore(
		persistedReducer,
		initialState,
		compose(
			applyMiddleware(...middleware)
			// window.__REDUX_DEVTOOLS_EXTENSION__ &&
			// 	window.__REDUX_DEVTOOLS_EXTENSION__()
		)
	);
	let persistor = persistStore(store);
	return { store, persistor };
};
