import React, { useState, useEffect, useRef } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import styles from "./Payment.module.css";
import PaymentForm from "./PaymentForm";
import SubscriptionSelect from "./SubscriptionSelect";
import PaymentConfirmation from "./PaymentConfirmation";

import { useDispatch, useSelector } from "react-redux";
import { setCurrentModal, closeModal, setErrorData } from "../../../redux/actions/modal";

import Spinner from "../../../components/general/spinner/Spinner";
import ReactGA from "react-ga4";
import * as moment from "moment";
// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.

export default function Payment({ user }) {
	const dispatch = useDispatch();

	const [clientSecret, setClientSecret] = useState("");
	const [selectedSubscription, setSelectedSubscription] = useState(null);
	const [paymentSucceeded, setPaymentSucceeded] = useState(null);
	const [displayMessage, setDisplayMessage] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [products, setProducts] = useState(null);
	const [apiKey, setApiKey] = useState(null);
	const [stripePromise, setStripePromise] = useState(null);

	useEffect(() => {
		// Load the Stripe library once the api key is retrieved
		if (apiKey !== null) {
			setStripePromise(loadStripe(apiKey));
		}
	}, [apiKey]);

	useEffect(() => {
		// ReactGA.pageview("payment_page");

		getSubscriptions();
	}, []);

	useEffect(() => {
		createPaymentIntent();
	}, [selectedSubscription]);

	let createPaymentIntent = async () => {
		// Called in useEffect when subscription is selected.
		// Create payment intent when a subscription (other than "free") is selected.
		if (selectedSubscription) {
			setIsLoading(true);
			await fetch("https://royia0wuq3.execute-api.us-east-2.amazonaws.com/prod/payment/create", {
				method: "POST",
				headers: {
					Authorization: user.signInUserSession.idToken.jwtToken,
				},
				body: JSON.stringify({
					paymentType: ["card"],
					description: `MuscoVision Access Pass - Web - ${selectedSubscription.name}`,

					metadata: {
						userID: user.username,
						userEmail: user.attributes.email,
						passID: selectedSubscription.passID,
					},
				}),
			})
				.then((res) => res.json())
				.then((data) => {
					setClientSecret(data.client_secret);
				})
				.catch((err) => {
					dispatch(
						setErrorData({
							message: "An unexpected error occurred",
						})
					);
					dispatch(setCurrentModal("error"));
				});
			setIsLoading(false);
		}
	};
	const getSubscriptions = async () => {
		await fetch(`https://royia0wuq3.execute-api.us-east-2.amazonaws.com/prod/payment/passes`, {
			headers: {
				Authorization: user.signInUserSession.idToken.jwtToken,
			},
		})
			.then((response) => {
				if (response.status !== 200) {
					throw new Error();
				} else {
					return response.text();
				}
			})
			.then((res) => {
				const data = JSON.parse(res);
				setApiKey(data.api_key);
				let subscriptions = data.products;
				setProducts(subscriptions);
			})
			.catch((err) => {
				dispatch(
					setErrorData({
						message: "An unexpected error occurred",
					})
				);
				dispatch(setCurrentModal("error"));
			});
		setIsLoading(false);
	};

	const getPassExpiration = () => {
		var exp = moment().add(selectedSubscription.passLength, "days").format("MMMM DD, YYYY");
		// let exp = moment(new Date() + selectedPass.amount).format("MMMM DD, YYYY");
		return exp;
	};
	const appearance = {
		theme: "flat",
		rules: {
			".Label": { color: "#fff" },
		},
	};
	const options = {
		clientSecret,
		appearance,
	};
	if (isLoading) {
		return (
			<div style={spinnerWrapper}>
				<Spinner color="250, 250, 250" />
			</div>
		);
	}
	return (
		<div className={styles.formWrapper}>
			{paymentSucceeded ? (
				// Render confirmation modal. Send the selected pass.
				<PaymentConfirmation selectedSubscription={products.find((i) => i.passID === selectedSubscription.passID)} />
			) : (
				<div className={styles.paymentForm}>
					{isLoading ? (
						<div style={spinnerWrapper}>
							<Spinner color="250, 250, 250" />
						</div>
					) : (
						<>
							{!displayMessage ? (
								<>
									{products !== null && products ? (
										<SubscriptionSelect
											subscriptions={products}
											selectedValue={selectedSubscription}
											setSubscription={(sub) => {
												setSelectedSubscription(sub);
											}}
										/>
									) : null}
									{selectedSubscription ? (
										<p className={styles.subscriptionMessage}>
											<span>NOTE: </span>
											{`This subscription will end on ${getPassExpiration()}.`}
										</p>
									) : null}
									{clientSecret && selectedSubscription ? (
										<Elements options={options} stripe={stripePromise}>
											<PaymentForm
												setPaymentSucceeded={() => setPaymentSucceeded(true)}
												clientSecret={clientSecret}
												pass={selectedSubscription}
											/>
										</Elements>
									) : null}
								</>
							) : (
								<p style={{ color: "red" }}>{displayMessage}</p>
							)}
						</>
					)}
				</div>
			)}
		</div>
	);
}

const spinnerWrapper = {
	display: "flex",
	height: "70vh",
	justifyContent: "center",
	alignItems: "center",
};
