import { TOGGLE_LOGIN, SET_USER_TO_CONFIRM } from "../actionTypes";

export const toggleLogin = (bool) => ({
	type: TOGGLE_LOGIN,
	payload: bool,
});

export const setUserToConfirm = (user) => ({
	type: SET_USER_TO_CONFIRM,
	payload: user,
});
