import React from "react";
import styles from "./LoadingPlaceholder.module.css";

import { motion } from "framer-motion";
export default function LoadingPlaceholder() {
	return (
		<div className={styles.row}>
			{[...Array(3)].map((e, i) => (
				<motion.div
					key={i}
					className={styles.placeholderBlock}
					animate={{ opacity: 0.25 }}
					transition={{
						yoyo: Infinity,
						duration: 0.75,
						delay: i * 0.2,
					}}
				>
					<div className={styles.placeholderImage}></div>
					<div className={styles.placeholderText}></div>
				</motion.div>
			))}
		</div>
	);
}
