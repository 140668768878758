import React, { useState } from "react";
import SearchIcon from "../../img/Search-icon.svg";

const SearchBar = (props) => {
	const [searchStr, setSearchStr] = useState("");

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (searchStr.length !== 0) {
			if (searchStr !== " ") {
				let replaceSpaces = searchStr.replace(/\s/g, "+");
				window.location = `/search?q=${replaceSpaces}`;
			} else {
				window.location = `/search?q=`;
			}
		}
	};

	return (
		<form
			onSubmit={handleSubmit}
			style={{
				...props.formStyles,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<input
			data-testid="search"
				style={{ ...props.barStyles, marginRight: "3px" }}
				type="text"
				placeholder={props.placeholder}
				value={searchStr}
				onChange={(e) => setSearchStr(e.target.value)}
				maxLength="180"
			/>

			<button type="submit" style={{ ...props.buttonStyles, border: "none" }}>
				<img height="15" src={SearchIcon} alt="Go" />
			</button>
		</form>
	);
};

export default SearchBar;
