import React, { useEffect, useState } from "react";
import styles from "./Nearby.module.css";
import Spinner from "./spinner/Spinner";
import usePlacesAutocomplete, {
	getGeocode,
	getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";

const LocationSearch = ({ changeLocation, setCurrentLocation }) => {
	const {
		ready,
		value,
		suggestions: { status, data },
		setValue,
		clearSuggestions,
	} = usePlacesAutocomplete({
		requestOptions: {
			/* Define search scope here */
		},
		debounce: 300,
	});

	const [loading, setLoading] = useState(false);
	useEffect(() => {
		if (data.length > 0) {
			setLoading(false);
		}
	}, [data]);
	const ref = useOnclickOutside(() => {
		// When user clicks outside of the component, we can dismiss
		// the searched suggestions by calling this method
		clearSuggestions();
	});

	const handleInput = (e) => {
		// Update the keyword of the input element
		if (e.target.value.length > 0) {
			setLoading(true);
		}

		setValue(e.target.value);
	};

	const handleSelect =
		({ description }) =>
		() => {
			// When user selects a place, we can replace the keyword without request data from API
			// by setting the second parameter as "false"
			setValue(description, false);
			clearSuggestions();

			// Get latitude and longitude via utility functions
			getGeocode({ address: description })
				.then((results) => getLatLng(results[0]))
				.then(({ lat, lng }) => {
					changeLocation({ lat, lng });
				})
				.catch((error) => {
					console.log("😱 Error: ", error);
				});
		};

	const renderSuggestions = data.map((suggestion) => {
		console.log("YO!");
		const {
			id,
			structured_formatting: { main_text, secondary_text },
		} = suggestion;

		return (
			<li key={id} onClick={handleSelect(suggestion)}>
				<strong>{main_text}</strong> <small>{secondary_text}</small>
			</li>
		);
	});

	return (
		<div className={styles.locationSearch}>
			<button
				data-testid="currentLocationBtn"
				onClick={() => setCurrentLocation()}
				className={styles.currentLocationBtn}
			>
				<svg
					width="13px"
					height="17px"
					viewBox="0 0 13 17"
					version="1.1"
					xlink="http://www.w3.org/1999/xlink"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M0 6.23779C0 2.67722 2.98801 -0.203435 6.60225 0.0112726C9.80497 0.172303 12.3994 2.85615 12.4888 6.07676C12.5246 7.27554 12.2204 8.40276 11.6658 9.35105L6.99588 16.5974C6.65593 17.1342 5.85078 17.1342 5.51082 16.5974L0.840938 9.35105C0.304169 8.43854 0 7.365 0 6.23779ZM3.18483 6.2199C3.18483 7.91966 4.56253 9.27948 6.24441 9.27948C7.92628 9.27948 9.30399 7.91966 9.30399 6.2199C9.30399 4.53802 7.92628 3.16032 6.24441 3.16032C4.54464 3.16032 3.18483 4.53802 3.18483 6.2199Z"
						id="Shape-Copy-3"
						fill="#1DA1F2"
						fillRule="evenodd"
						stroke="none"
					/>
				</svg>
				USE CURRENT LOCATION
			</button>
			{/* <span className={styles.orText}>OR</span>
			<div className={styles.inputArea}>
				<input
					value={value}
					onChange={handleInput}
					disabled={!ready}
					placeholder="ENTER A CITY, ZIP CODE, OR FACILITY"
				/>

			
				{status === "OK" ? (
					<ul ref={ref} className={styles.dropdown}>
						{renderSuggestions()}
					</ul>
				) : loading ? (
					<ul ref={ref} className={styles.dropdown}>
						<div className={styles.spinnerContainer}>
							<Spinner color="255, 255, 255" />
						</div>
					</ul>
				) : null}
			</div> */}

			{/* <button className={styles.searchBtn}>SEARCH</button> */}
		</div>
	);
};

export default LocationSearch;
