import React, { useEffect } from "react";
import styles from "./PrivacyPolicy.module.css";
import ReactGA from "react-ga4";
export default function PrivacyPolicy() {
	//GA page view
	useEffect(() => {
		// ReactGA.pageview(window.location.pathname + window.location.search);
	}, []);
	return (
		<div className={styles.privacyPolicy}>
			<div style={{ textAlign: "center" }}>
				<h2>Online Privacy Policy</h2>
				<p>Last updated February 17, 2022</p>
				<p>Effective Date of This Online Privacy Policy</p>
				<p>This Online Privacy Policy is effective as of July 1, 2020.</p>
			</div>
			<h4>Scope of This Online Privacy Policy</h4>
			<p>
				This Online Privacy Policy describes how Musco Sports Lighting, LLC (hereinafter “Musco,” “we,” “us,” and “our”)
				protects your privacy when you use the MuscoVision® site, www.muscovision.com (hereinafter “Site”). Please refer
				to the MuscoVision{" "}
				<a href="https://www.muscovision.com/docs/application_privacy_policy.pdf">App Privacy Policy</a> for more
				specific information related to the app.
			</p>
			<p>
				Please note that this Online Privacy Policy applies to the password protected areas of our Site and to the
				personal information we may receive in connection with your use of our products and services. Your purchase and
				use of products and services offered by Musco (hereinafter “Services”) may also be governed by separate
				agreements and waivers of rights that you have made with facility owners or sports leagues related to the
				streaming video content available on the Site. Where appropriate, this Site (including this Online Privacy
				Policy) may provide links to third party websites relating to Services. Our failure to provide a link to third
				party websites relating to Services shall not be deemed implication of permissions, rights, or restrictions of
				Services or third party content under this Online Privacy Policy. We are not responsible for examining,
				evaluating, or warranting any of the content or accuracy of third party websites. The inclusion of a link to a
				third party website does not imply our endorsement of said website. Before you use any website you should review
				the applicable contracts and policies.
			</p>
			<h4>Privacy Notices</h4>
			<p>
				This Online Privacy Policy may be modified or supplemented from time to time by posting relevant notices on
				specific areas of our Site (hereinafter “Privacy Notices”). For example, certain pages of our Site contain
				Privacy Notices providing details about the personal information we collect on those pages, why we need that
				information, and choices you may have about the ways we use that information.
			</p>
			<h4>Your Consent to This Online Privacy Policy</h4>
			<p>
				By using the Site, you are affirmatively agreeing to the collection, use, and disclosure of your personal
				information as described in this Online Privacy Policy. If you do not consent to the collection, use, and
				disclosure of your personal information as described in this Online Privacy Policy, please exit this Site
				immediately and do not use this Site.
			</p>
			<p>
				Your physical location may differ from the physical location of a server hosting this Site, and the physical
				location where your personal information submitted to or collected by this Site is collected, used, transferred,
				or stored, may differ from your physical location, the physical location of a server hosting this Site, or both
				your physical location and the physical location of a server hosting this Site. Please note that your use of
				this Site constitutes your affirmative agreement to the collection, use, transfer, or storage (hereinafter
				collectively “processing”) of your personal information in the United States of America or in any other country
				in the world.
			</p>
			<p>
				If you do not agree to the processing of your personal information anywhere in the world as described in this
				Online Privacy Policy, please exit this Site immediately and do not use this Site.
			</p>
			<h4>What Personal Information Do We Collect on Our Websites?</h4>
			<p>
				We have since inception of the Site or during the past twelve months, whichever is longer, and continue to
				collect various types of personal and non-personal information. Personal information is information that Musco
				can use to identify you as a natural person, including: (i) identifiers; such as your name, address, email
				address, phone number, and electronic tags posted to the Site; (ii) internet or other electronic network
				activity information, as more fully described below; (iii) commercial information, including records of Services
				purchased; (iv) financial information provided to purchase the Services which may include bank account number,
				credit card number or debit card number; (v) geolocation data related to sporting activities streamed from a
				specific facility; (vi) visual information for players participating in streamed sporting activities; and (vii)
				professional or employment information provided to us by you. Non-personal information is any information that
				does not identify a natural person and includes such things as a business name, address, website, phone number
				or email address.
			</p>
			<h4>Information You Volunteer</h4>
			<p>
				We collect the personal information you knowingly and voluntarily provide when you use our Site; for example,
				the information you provide when you submit a preliminary request for services, or email us with questions or
				comments. We use this information to provide the information, products, and services you request and as set
				forth below.
			</p>
			<h4>Information Sent to Us by Your Web Browser</h4>
			<p>
				We collect information that is sent to us automatically by your web browser. This information typically includes
				your IP address, the address of the web page you were visiting when you accessed our Site, the name of your
				operating system, the name and version of your browser, and the date and time of your visit. The information we
				receive depends on the settings on your web browser. Please check your browser if you want to learn what
				information your browser sends or how to change your settings.
			</p>
			<p>
				The information provided by your browser does not identify you personally. We use this non- personal information
				primarily to create statistics that help us improve our Site and make them more compatible with the technology
				used by our visitors. However, if you have created a user identity on one of your visits to our Site, or if you
				access the Site by clicking on a link in an email we have sent you, we may link the information provided by your
				browser to information that identifies you personally. In addition, we may review our server logs for security
				purposes, such as detecting intrusions into our network. If we suspect criminal activity, we might share our
				server logs -- which contain visitors’ IP addresses -- with the appropriate investigative authorities who could
				use that information to trace and identify individuals.
			</p>
			<h4>Information We Collect Using Cookies and Other Web Technologies</h4>
			<p>
				Like many commercial sites, we use "cookies," and similar technologies to collect information and manage our
				Site. A cookie is a small text file that is placed on your hard drive by a web server. Cookies cannot be used to
				install computer programs or deliver viruses to your computer. We may use two types of cookies -- session
				cookies and persistent cookies.
			</p>
			<p>
				A session cookie assigns a randomly-generated, unique identification number to your computer when you access our
				Site. A session cookie expires after you close your browser. Generally, we use session cookies to collect
				information about the ways visitors use our Site – which pages they visit, which links they use, and how long
				they stay on each page. This information is known as “clickstream data.” Session cookies may also be used to
				facilitate your interactions with our Site. We do not link clickstream data with any information that identifies
				you personally.
			</p>
			<p>
				We may also use persistent cookies. These cookies do not expire when you close your browser, but stay on your
				computer until they expire or you delete them. By assigning your computer a persistent, unique identifier, we
				are able to create a database of your previous choices and preferences. This permits us to offer you a
				personalized experience when you use our Site. Assigning your computer a persistent, unique identifier also
				helps us keep a more accurate account of how many people visit our Site, how often they return, how their use of
				our Site may vary over time, and the effectiveness of our promotional efforts.
			</p>
			<p>
				Neither session cookies nor persistent cookies identify you personally. If, however, you have created an account
				on our Site or if you access the Site by clicking on a link in an email we have sent you, we may link the
				information collected by our cookies to information that identifies you personally.
			</p>
			<p>
				If you do not wish to receive cookies, you may set your browser to reject cookies or to alert you when a cookie
				is placed on your computer. You may also delete our cookies as soon as you leave our Site. Although you are not
				required to accept our cookies when you visit our Site, if you set your browser to reject cookies, you will not
				be able to use all of the features and functionality of our Site.
			</p>
			<p>
				In addition to cookies, we may also use “web beacons,” analytics tools, and other web technologies to manage our
				Site and email. A web beacon is a small bit of computer code. Web beacons may be used to count how many people
				visit a web page or tell us whether an email has been opened. We may link the information we collect using web
				beacons, analytics tools, and other web technologies to information that identifies you personally. If you wish
				to opt out of cookies issued by Google Analytics, please see instructions{" "}
				<a href="https://tools.google.com/dlpage/gaoptout">here</a>. If you opt out of certain cookies, some parts of
				the Site may become inaccessible or not function properly.
			</p>
			<p>
				Musco may use the personal information collected through this Site for any of the following: to provide the
				information, products and services you request; to provide you with information about products and services
				offered by Musco and its affiliates; to send you survey invitations related to the Services; to display
				personalized content and advertising when you visit our Site; to improve our services; to improve our Site; to
				better market our products and services; and for any other purpose we disclose to you in a specific Privacy
				Notice.
			</p>
			<p>
				We may also use the personal information you provide for other business purposes such as customer service, fraud
				prevention, and providing you with information and offers we believe may be of interest to you.
			</p>
			<p>
				In addition, we may delete information that identifies you personally and study the balance of the information
				we collect through our Site to improve our programs and services; to improve the content, functionality,
				navigation and usability of our Site; to better understand the needs and interests of our customers and
				potential customers; and to better market our products and services.
			</p>
			<h4>Collection and Sharing of Personal Information by Third Parties</h4>
			<p>
				The data collection taking place on fields, courts, and associated areas and streamed by us is owned by the
				facility owner. Each facility owner shall be responsible to inform its players and any other persons and, if
				necessary, to obtain their consent to be recorded and to stream the video on the internet. Musco assume no
				responsibility for any players or any other persons who were recorded (including video and audio) without their
				consent and/or without being informed by the facility owner.
			</p>
			<h4>With Whom Do We Share Your Personal Information?</h4>
			<p>We have not in the past twelve months and will not sell your personal information.</p>
			<h4>Service Providers</h4>
			<p>
				We may share personal information collected through our Site with companies and organizations that perform
				services on our behalf; for example, companies that provide support services to us (such as employment services
				and web hosting services) or that help us market our products and services. When we share personal information
				with our third party service providers, these service providers are required to use the personal information we
				share with them to only perform services on our behalf and to protect the confidentiality of your personal
				information.
			</p>
			<h4>Protection of Our Websites and Others</h4>
			<p>
				We may disclose the personal information we collect through our Site when we, in good faith, believe disclosure
				is appropriate to comply with the law (or a court order or subpoena); to prevent or investigate a possible
				crime, such as fraud or identity theft; to enforce the terms of use or other agreements that govern your use of
				our Site; or to protect the rights, property, or safety of Musco, our users, or others; or to protect your vital
				interests.
			</p>
			<h4>Business Transfers</h4>
			<p>
				Information submitted to our Site is an asset of Musco and/or its affiliates and will become part of our normal
				business records. In addition to the transfer of information between affiliates as described in this policy,
				your personal information may be transferred to another company (either an affiliate or an unrelated third
				party) that has acquired the stock or assets of Musco, one of our affiliates, or one of our operating divisions,
				for example, as the result of a sale, merger, reorganization, dissolution, or liquidation. If such a transfer
				occurs, the acquiring company’s use of your personal information will still be subject to this Online Privacy
				Policy, any applicable Privacy Notices, and the privacy preferences you have expressed to us.
			</p>
			<h4>Specific Situations</h4>
			<p>
				Please read further to learn more about the ways we collect, use, and with whom we share personal information in
				specific situations:
			</p>
			<h4>Signing up for Services</h4>
			<p>
				When you complete an online application to watch the streaming video and related services, we collect the
				personal information you submit on the application and use that information as set forth in this Online Privacy
				Policy. We also collect any information that you submit to the Site following the initial application as set
				forth in this Online Privacy Policy.
			</p>
			<h4>e-Bill and Online Payment Gateway</h4>
			<p>
				Like most businesses we provide customers the ability to make electronic payments. This is accomplished using
				payment “gateways”. A payment gateway is an e-commerce application service providers use to process credit card
				payments for e-businesses, online retailers, bricks and clicks, or traditional brick and mortar. Payment
				gateways protect credit card details by encrypting sensitive information, such as credit card numbers, to ensure
				that information is passed securely between the customer and the merchant and also between merchant and the
				payment processor.
			</p>
			<p>
				When you make an online payment, we direct you to the payment gateway of our billing service provider. The use
				of personal information collected by our billing service provider is governed by their privacy policy. All
				credit card information submitted when you submit a payment via the payment gateway is collected, processed, and
				stored by our billing service provider in accordance with their privacy policy. When you provide credit card
				information through our billing service provider’s payment gateway, your information goes directly from your
				computer to a server operated by our billing service provider and is collected, processed, and stored per PCI
				Level 1 compliance standards; see <a href="https://www.pcisecuritystandards.org/"></a>here for additional
				information regarding PCI security standards. For your security, our billing service provider does not share
				your full credit card information with us.
			</p>
			<h4>Contacting Us</h4>
			<p>
				When you email an inquiry to us, we receive your name, address, email address, and any other information you
				choose to include in your email. The content of any email inquiries received by Musco shall be deemed to be
				non-confidential. Any inquiries or materials sent to Musco through this Site shall become the sole property of
				Musco and may be used by Musco for any purpose without payment of any compensation, including for marketing
				purposes. Please be assured, however, that we will not sell the information you include in your email to any
				third party unrelated to the Services you access through this Site or otherwise specified in this Online Privacy
				Policy.
			</p>
			<h4>Access to Your Personal Information (Requests to Know or Requests to Delete)</h4>
			<p>
				Consumers have the right to request that Musco disclose the personal information it collects (Request to Know)
				and also have the right to request the deletion of such personal information (Request to Delete). To submit a
				Request to Know or Request to Delete, please email privacy@musco.com or call 800-825-6030. We will confirm
				receipt of your request within the time limit set out by the applicable privacy legislation and will in turn
				request at least two (2) pieces of personal information from you to verify your identity prior to disclosure.
				You may also designate an authorized agent to request the personal information on your behalf as provided by
				applicable privacy legislation. We will use reasonable efforts to comply with your request as required by
				applicable law. Musco will separately confirm Requests to Delete and will provide detail with respect to Musco’s
				compliance with the request in its response. We will maintain records of all requests in accordance with
				applicable privacy legislation.
			</p>
			<p>
				We respect your choice to exercise your privacy rights, and we will not discriminate against you for such
				exercise. We also respect your choice to discontinue receipt of mail from Musco. In this event, please let us
				know by removing your name from the mailing list as instructed on the email messages we send to you.
			</p>
			<h4>Retention and Protection of Personal Information</h4>
			<p>
				Musco retains the personal information collected on this Site as long as necessary to provide the services,
				products and information you request or as permitted by applicable law. Musco has implemented an information and
				data security program that contains administrative, technical and physical controls designed to reasonably
				safeguard data, including your personal information. We take reasonable precautions to provide a level of
				security appropriate to the sensitivity of the personal information we collect. Although we use reasonable
				measures to help protect your personal information against unauthorized use or disclosure, we cannot guarantee
				the security of information provided over the internet or stored in our databases.
			</p>
			<h4>Submitting Requests to Know and Requests to Delete Or Questions Concerning This Policy</h4>
			<p>
				If you would like to submit a Request to Know or Request to Delete your personal information or if you have any
				questions or concerns regarding this Policy, or would like to request a written copy of this Policy, please
				contact us by emailing privacy@musco.com or by writing to:
			</p>
			<p>
				Musco Sports Lighting, LLC Attn: Lance Livingston, Data Protection Officer 100 1st Avenue West Oskaloosa, Iowa
				52577 800-825-6030
			</p>
			<h4>This Online Privacy Policy May Change</h4>
			<p>
				We reserve the right to update or modify this Online Privacy Policy, at any time and without prior notice, by
				posting the revised version of this Online Privacy Policy on the Site. These changes will only apply to personal
				information we collect after we have posted the revised Online Privacy Policy on our Site. Your use of our Site
				following any such change constitutes your agreement that all personal information collected from or about you
				through this Site after the revised Online Privacy Policy is posted will be subject to the terms of the revised
				Online Privacy Policy.
			</p>
			<p>
				To alert you to these changes, we will provide a notice at the top of this Online Privacy Policy for at least 30
				days after the new effective date and highlight the changes so that you can locate them easily. Unless the
				change is a minor change (such as a change in our contact information) or a non-substantive change (such as the
				reformatting of our Online Privacy Policy), we will also post notices (a) on our home page and/or on the
				relevant pages of our Site and (b) on any notice providing highlights of this Online Privacy Policy for 30 days
				after the new effective date to alert you to the change.
			</p>
			<p>
				From time to time, for example, because of changes in the law or technology or the way we operate our business,
				we may ask your permission to use the personal information we have collected from you in new or different ways.
				We will do this by sending you a notice by email or postal mail at the last email or postal address you provided
				us, asking you to agree to the change. If you do not expressly agree to the change, we may, at our discretion,
				delete all or some of your personal information from our databases to avoid using your information in ways to
				which you have not consented.
			</p>
			<p>
				You may access the current version of this Online Privacy Policy at any time by clicking on the link marked
				"Privacy Policy" on the Policies page of our Site. You may print and keep a copy of this Policy.
			</p>
			<h4>Effective Date of This Online Privacy Policy</h4>
			<p>This Online Privacy Policy is effective as of July 1, 2020.</p>
			<h4>Children Under 13</h4>
			<p>
				This Site is not directed at nor targeted to children under the age of 13. We do not use our Site to knowingly
				solicit personal information from or market to children under the age of 13 except where we receive verifiable
				parental consent. In the case of minors under the age of 13 we will collect the email addresses of both the user
				and the parents or legal guardians. We will use the parents’ email address to send the parents messages such as
				activation of the child’s account, notification about privacy practices, and communications regarding the
				child’s account or about features of our site. Anyone under the age of 18 must review the Terms of Use and this
				Policy with his/her parent(s) or legal guardian(s) to ensure that both the user and the parent(s) or legal
				guardian(s) understand and accept both the Terms of Use and this Policy. We will not condition participation in
				an online activity on the disclosure of more information than is reasonably necessary to participate in the
				activity. We strongly recommend that users under the age of 13 would ask their guardians for permission before
				sending any information about themselves to anyone. We also encourage guardians to teach their children about
				safe internet use practices.
			</p>
			<h4>Governing Law</h4>
			<p>
				Musco is a United States company. The servers that support this Site may be located in the United States or
				other countries. Data protection laws vary from country to country; however, we will protect and process your
				information in accordance with this Policy no matter where your data is collected or stored. If you are located
				in a country outside the United States, by using this Site you consent to your information being transferred or
				transmitted to, or stored and processed in the United States or other countries outside of where you live for
				the purposes as described in this policy.
			</p>
			<p>
				This Policy shall be governed by the laws of the State of Iowa, United States of America without regard to its
				conflicts of law provisions.
			</p>
		</div>
	);
}
