import React from "react";
import styles from "../Auth.module.css";
import * as moment from "moment";

function SubscriptionSelect({ selectedValue, setSubscription, subscriptions }) {
	const handleChange = (event) => {
		let passID = event.target.value;
		console.log(passID);
		let passTitle = subscriptions.find((i) => i.passID === passID).name;

		setSubscription(passID, passTitle);
	};
	const getPassExpiration = () => {
		let selectedPass = subscriptions.find((i) => i.passID === selectedValue);

		var exp = moment()
			.add(selectedPass.passLength, "days")
			.format("MMMM DD, YYYY");
		// let exp = moment(new Date() + selectedPass.amount).format("MMMM DD, YYYY");
		return exp;
	};
	return (
		<div className={styles.subscriptionSelect}>
			<h3 className={styles.subscriptionSelectTitle}>PICK YOUR PASS</h3>
			{subscriptions.map((sub, i) => (
				<div key={i} className={styles.radioBtnGroup}>
					<span className={styles.radioInput}>
						<input
							checked={selectedValue === sub.passID}
							value={sub.passID}
							type="radio"
							onChange={handleChange}
						/>
					</span>

					<label className={styles.radioBtnLabel}>{`${sub.name} ${
						sub.passID !== "free" ? "$" + sub.amount / 100 : ""
					}`}</label>
				</div>
			))}

			{selectedValue !== null && selectedValue !== "free" ? (
				<p className={styles.subscriptionMessage}>
					<span>NOTE: </span>
					{`This subscription will end on ${getPassExpiration()}.`}
				</p>
			) : null}
		</div>
	);
}

export default SubscriptionSelect;
