import React from "react";
import styles from "./Payment.module.css";
import SuccessAnimation from "./successAnimation";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import * as moment from "moment";

export default function PaymentConfirmation({ selectedSubscription }) {
	const dispatch = useDispatch();
	const getPassExpiration = () => {
		var exp = moment().add(selectedSubscription.passLength, "days").format("MMMM DD, YYYY");
		// let exp = moment(new Date() + selectedPass.amount).format("MMMM DD, YYYY");
		return exp;
	};
	return (
		<div className={styles.paymentConfirmation}>
			<SuccessAnimation />
			<h3>
				THANK YOU FOR
				<br />
				YOUR PURCHASE!
			</h3>
			<Link to="/" className={styles.submitBtn}>
				START WATCHING
			</Link>
			{selectedSubscription ? (
				<p className={styles.subscriptionMessage} style={{ marginTop: "15px" }}>
					<span>NOTE: </span>
					{`This subscription will end on ${getPassExpiration()}.`}
				</p>
			) : null}
			<p className={styles.infoText}>
				We have received your payment information and are processing your payment. You will receive a confirmation email
				at the address you provided.
			</p>
		</div>
	);
}
