import {
	TOGGLE_SIDEDRAWER,
	TOGGLE_FILTERMENU,
	SET_SIDEDRAWER_STATE,
	SET_SCREEN_WIDTH,
} from "../actionTypes";

export const toggleSideDrawer = () => ({
	type: TOGGLE_SIDEDRAWER,
});
export const setSideDrawerState = (isOpen) => ({
	type: SET_SIDEDRAWER_STATE,
	payload: isOpen,
});
export const toggleFilterMenu = () => ({
	type: TOGGLE_FILTERMENU,
});

export const setScreenWidth = (width) => ({
	type: SET_SCREEN_WIDTH,
	payload: width,
});
