import React from "react";
import styles from "./Modal.module.css";
import Share from "./shareLink/ShareLink";
import Error from "./error/Error";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../redux/actions/modal";
import { motion } from "framer-motion";
import Spinner from "../general/spinner/Spinner";
import CloseIcon from "../../img/X-symbol-black.svg";
import Login from "./auth/Login";
import Register from "./auth/Register";
import SendConfirmationCode from "./auth/SendConfirmationCode";
import ConfirmAccount from "./auth/ConfirmAccount";

import ResetPassword from "./auth/ResetPassword";
import Payment from "./auth/payment";
import Profile from "./profile/Profile";

const Modal = () => {
	const modalOpen = useSelector((state) => state.modal.modalOpen);
	const loading = useSelector((state) => state.modal.loading);
	const currentModal = useSelector((state) => state.modal.currentModal);
	const loadingMessage = useSelector((state) => state.modal.loadingMessage);
	const errorData = useSelector((state) => state.modal.errorData);

	const dispatch = useDispatch();

	return (
		<>
			{modalOpen ? (
				<div className={styles.modalWrapper}>
					<motion.div
						initial={{ scale: 0.75, opacity: 0 }}
						animate={{ scale: 1, opacity: 1 }}
						transition={{
							type: "spring",
							stiffness: 240,
							damping: 20,
						}}
						className={styles.modal}
					>
						{loading ? (
							<div className={styles.loadingOverlay}>
								<div className={styles.loadingContainer}>
									<Spinner color="0, 93, 164" />
									<span>{loadingMessage}</span>
								</div>
							</div>
						) : null}
						<button data-testid="closeModal" className={styles.closeBtn} onClick={() => dispatch(closeModal())}>
							<img src={CloseIcon} alt="Close Modal" />
						</button>
						{currentModal === "share" ? (
							<Share />
						) : currentModal === "error" ? (
							<Error errorData={errorData} />
						) : currentModal === "login" ? (
							<Login />
						) : currentModal === "register" ? (
							<Register />
						) : currentModal === "send_confirmation_code" ? (
							<SendConfirmationCode />
						) : currentModal === "confirm_account" ? (
							<ConfirmAccount />
						) : currentModal === "reset_password" ? (
							<ResetPassword />
						) : currentModal === "payment" ? (
							<Payment />
						) : currentModal === "profile" ? (
							<Profile />
						) : null}
					</motion.div>
				</div>
			) : null}
		</>
	);
};
export default Modal;
