import React from "react";
import styles from "./Search.module.css";
import SearchBar from "../../../components/filters/SearchBar";
import { Link } from "react-router-dom";
import NearbyIcon from "../../../img/nearby-link-icon.svg";

export default function FindAGame() {
	return (
		<section className={styles.find}>
			<h1 className={styles.findTitle}>find a game</h1>
			<div className={styles.blueHorizontalRule}></div>
			<p className={styles.findDesc}>Search for a game or facility.</p>
			<div className={styles.searchBar}>
				<SearchBar placeholder="SEARCH" />
			</div>

			<Link to="/nearby" className={styles.gamesNearMeLink}>
				<img
					style={{ marginRight: ".5rem" }}
					src={NearbyIcon}
					alt="Games Near Me Icon"
				/>
				FIND GAMES NEAR ME
			</Link>
		</section>
	);
}
