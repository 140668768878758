import React from "react";

import Email from "./Email";
import Password from "./Password";
import Subscription from "./Subscription";

export default function AccountDetails({ userData, refreshData }) {
	return (
		<>
			<h2>MY ACCOUNT</h2>

			<Email
				email={userData.attributes.email}
				user={userData}
				refreshData={() => refreshData()}
			/>
			<Password user={userData} refreshData={() => refreshData()} />
			<Subscription user={userData} refreshData={() => refreshData()} />
		</>
	);
}
