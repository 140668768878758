import React, { useEffect, useState } from "react";
import styles from "./Facility.module.css";
import { withRouter, Redirect } from "react-router-dom";
import ReactGA from "react-ga4";
import GetBroadcasts from "../../../components/data/getBroadcasts/GetBroadcasts";
import { useDispatch } from "react-redux";

const Facility = (props) => {
	const dispatch = useDispatch();
	const [siteData, setSiteData] = useState({ data: [], isLoading: true });
	const [error, setError] = useState(false);

	//GA page view
	useEffect(() => {
		ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Facility Page" });
	}, []);

	const siteId = props.match.params.siteId;
	useEffect(() => {
		getSiteData(siteId);
	}, []);

	const getSiteData = async (siteId) => {
		// Get currently live games at this facility
		await fetch(`https://arbiter.musco.io/pub/facility?id=${siteId}`)
			.then((response) => response.text())
			.then((result) => {
				const data = JSON.parse(result);
				setSiteData({ data: data, isLoading: false });
			})
			.catch((err) => {
				setError(true);
			});
	};

	return (
		<div className={styles.facility}>
			{error ? <Redirect to="/error/facility-not-found" /> : null}
			<section
				data-testid="header"
				className={styles.facilityImg}
				style={siteData.data ? { backgroundImage: `url(${siteData.data.large_image})` } : null}
			></section>
			<section className={styles.facilityHeader}>{siteData.data ? <h1>{siteData.data.title}</h1> : null}</section>
			<section className={styles.contentSection}>
				<div className={styles.expandableList}>
					{/* live broadcasts */}
					<GetBroadcasts facilityID={siteId} updateParent={() => null} isLoading={false} viewerStatus={"streaming"} />
				</div>
				<div className={styles.expandableList}>
					{/* upcoming broadcasts */}
					<GetBroadcasts facilityID={siteId} updateParent={() => null} isLoading={false} viewerStatus={"scheduled"} />
				</div>
				<div className={styles.expandableList}>
					{/* archived broadcasts */}
					<GetBroadcasts facilityID={siteId} updateParent={() => null} isLoading={false} viewerStatus={"archived"} />
				</div>
			</section>
		</div>
	);
};

export default withRouter(Facility);
