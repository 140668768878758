import React, { useEffect, useRef, useState } from "react";

import videojs from "video.js";

import "video.js/dist/video-js.css";

import "videojs-mux";

export default function VideoPlayer({
	src,

	broadcastData,

	user,

	setPlayerError,
}) {
	const videoRef = useRef(null);

	const playerRef = useRef(null);

	const [error, setError] = useState(false);

	useEffect(() => {
		if (videoRef.current) {
			const video = videoRef.current;

			playerRef.current = videojs(video, {
				sources: [{ src, type: "application/x-mpegURL" }],

				autoplay: true,

				controls: true,

				responsive: true,

				fluid: true,

				plugins: {
					mux: {
						debug: false,

						data: {
							env_key: "s3lebqhfr5ine2bl51t8ht9vq",

							player_name: "WEB",

							player_init_time: Date.now(),

							viewer_user_id: user !== null ? user.username : null,

							sub_property_id: broadcastData.site_id,

							player_version: "1.0.0",

							video_id: broadcastData.id,

							video_title: broadcastData.title,

							// video_series: broadcastData.field_id,

							video_duration: broadcastData.expected_duration,

							video_stream_type: broadcastData.status,
						},
					},
				},
			}).on("error", function (e) {
				setPlayerError();
			});
		}
	}, [videoRef]);

	return (
		<video
			controls
			autoPlay
			preload="auto"
			ref={videoRef}
			style={{ width: "100%" }}
			className="video-js vjs-big-play-centered"
		/>
	);
}
