import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggleSideDrawer } from "../../../redux/actions/general";
import styles from "./SideDrawer.module.css";
import { setCurrentModal } from "../../../redux/actions/modal";
const Nav = () => {
	const dispatch = useDispatch();
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
	function toggleSubmenu(e) {
		if (!e.target.parentElement.classList.contains("isOpen")) {
			e.target.childNodes[1].style.transform = "rotate(90deg)";
			e.target.parentElement.classList.add("isOpen");
			e.target.style.backgroundColor = "rgba(270, 270, 270, .5)";
			e.target.parentElement.childNodes[1].style.maxHeight = "400px";
		} else {
			e.target.childNodes[1].style.transform = "rotate(0deg)";
			e.target.parentElement.classList.remove("isOpen");
			e.target.style.backgroundColor = "transparent";
			e.target.parentElement.childNodes[1].style.maxHeight = "0";
		}
	}

	return (
		<nav className={styles.nav}>
			<ul>
				<li className={styles.parentListItem}>
					<Link
						onClick={() => dispatch(toggleSideDrawer())}
						className={styles.navItem}
						to="/"
					>
						HOME
					</Link>
				</li>

				<li className={styles.parentListItem}>
					<span className={styles.navItem} onClick={toggleSubmenu}>
						SPORTS
						<svg
							width="8px"
							height="14px"
							viewBox="0 0 8 14"
							version="1.1"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M0 0L6.66666 6.25L1.38778e-16 12.5"
								transform="matrix(1 -2.145688E-07 -2.145688E-07 -1 0.5 13)"
								id="Line-3-Copy-10"
								fill="none"
								stroke="#FFFFFF"
								strokeWidth="1"
								strokeLinecap="square"
							/>
						</svg>
					</span>
					<ul className={styles.subMenu} style={{ maxHeight: "0" }}>
						<li>
							<Link
								className={styles.subMenuLink}
								onClick={() => dispatch(toggleSideDrawer())}
								to="/search?q=&sports=baseball"
							>
								BASEBALL
							</Link>
						</li>
						<li>
							<Link
								className={styles.subMenuLink}
								onClick={() => dispatch(toggleSideDrawer())}
								to="/search?q=&sports=football"
							>
								FOOTBALL
							</Link>
						</li>
						<li>
							<Link
								className={styles.subMenuLink}
								onClick={() => dispatch(toggleSideDrawer())}
								to="/search?q=&sports=basketball"
							>
								BASKETBALL
							</Link>
						</li>
						<li>
							<Link
								className={styles.subMenuLink}
								onClick={() => dispatch(toggleSideDrawer())}
								to="/search?q=&sports=soccer"
							>
								SOCCER
							</Link>
						</li>
						<li>
							<Link
								className={styles.subMenuLink}
								onClick={() => dispatch(toggleSideDrawer())}
								to="/search?q=&sports=softball"
							>
								SOFTBALL
							</Link>
						</li>
					</ul>
				</li>
				<li className={styles.parentListItem}>
					<Link
						onClick={() => dispatch(toggleSideDrawer())}
						className={styles.navItem}
						to="/nearby"
					>
						GAMES NEARBY
					</Link>
				</li>
				<li className={styles.parentListItem}>
					<Link
						onClick={() => dispatch(toggleSideDrawer())}
						className={styles.navItem}
						to="/facilities"
					>
						FACILITIES
					</Link>
				</li>
			</ul>
			<div className={styles.horizontalRule}></div>
			<ul className={styles.additionalLinks}>
				{isAuthenticated ? (
					<li>
						<button
							className={styles.navItem}
							onClick={() => dispatch(setCurrentModal("profile"))}
						>
							MY ACCOUNT
						</button>
					</li>
				) : null}

				<li>
					<a
						rel="noopener noreferrer"
						target="_blank"
						href="https://www.musco.com/muscovision/"
					>
						GET MUSCOVISION
					</a>
				</li>
			</ul>
		</nav>
	);
};

export default Nav;
