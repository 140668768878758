import React from "react";
import Desktop from "./desktop";
import Mobile from "./mobile";
import { useSelector } from "react-redux";

const FeaturedCarousel = ({ broadcasts, isLoading }) => {
	const screenWidth = useSelector((state) => state.general.screenWidth);

	return (
		<div style={{ margin: "0 auto", maxWidth: "1200px" }}>
			{/* Render the Desktop or Mobile component based on the current device width */}

			{screenWidth > 875 ? (
				<Desktop broadcasts={broadcasts} isLoading={isLoading} />
			) : (
				<Mobile broadcasts={broadcasts} isLoading={isLoading} />
			)}
		</div>
	);
};

export default FeaturedCarousel;
