import React, { useState, useEffect } from "react";

import AccountDetails from "./accountDetails/AccountDetails";

import styles from "./Profile.module.css";
import ReactGA from "react-ga4";
import { Auth } from "aws-amplify";
import Spinner from "../../../components/general/spinner/Spinner";
import { Redirect } from "react-router-dom";
export default function Profile() {
	const [authenticationFailed, setAuthenticationFailed] = useState(false);
	const [userData, setUserData] = useState(null);
	//GA page view
	useEffect(() => {
		authenticateUser();
	}, []);
	let authenticateUser = async () => {
		await Auth.currentAuthenticatedUser({ bypassCache: true })
			.then((user) => {
				setUserData(user);
				console.log(user);
			})
			.catch((err) => {
				setAuthenticationFailed(true);
				console.log(err);
			});
	};

	return (
		<div className={styles.profile}>
			{!authenticationFailed ? (
				<div className={styles.profileContent}>
					{userData !== null ? (
						<>
							<AccountDetails userData={userData} refreshData={() => authenticateUser()} />
						</>
					) : (
						<div style={{ margin: "200px 0" }}>
							<Spinner color="0, 93, 164" />
						</div>
					)}
				</div>
			) : (
				<Redirect to="/" />
			)}
		</div>
	);
}
