import {
	CLOSE_MODAL,
	SET_CURRENT_MODAL,
	SET_LOADING,
	SET_ERROR_DATA,
	NEW_TAG_ADDED,
} from "../actionTypes";

export const closeModal = () => ({
	type: CLOSE_MODAL,
});

export const setCurrentModal = (currentModal) => ({
	type: SET_CURRENT_MODAL,
	payload: currentModal,
});

export const setLoading = ({ isLoading, message }) => ({
	type: SET_LOADING,
	payload: { isLoading, message },
});

export const setErrorData = ({ message }) => ({
	type: SET_ERROR_DATA,
	payload: { message: message },
});

export const setNewTagAdded = (bool) => ({
	type: NEW_TAG_ADDED,
	payload: bool,
});
