import React, { useState, useEffect, useRef } from "react";

import { useSelector } from "react-redux";

import { withRouter } from "react-router-dom";

import GetBroadcasts from "../../../components/data/getBroadcasts/GetBroadcasts";

import VideoPlayer from "./videoPlayer";

import { Helmet } from "react-helmet";

import styles from "./Broadcast.module.css";

import Spinner from "../../../components/general/spinner/Spinner";

import ReactGA from "react-ga4";

import AccessPrompt from "./accessPrompt/AccessPrompt";

import { Auth } from "aws-amplify";

import Moment from "react-moment";

import useEffectSkipFirst from "../../../components/utils/useEffectSkipFirst";

import Info from "./info/Info";

function Broadcast(props) {
	const paymentSucceeded = useSelector((state) => state.user.paymentSucceeded);

	const select = useRef(null);

	const [error, setError] = useState(null);

	const [playerError, setPlayerError] = useState(null);

	const [startTime, setStartTime] = useState(null);

	const [tabSelected, setTabSelected] = useState("streaming");

	const [vidSrc, setVidSrc] = useState(null);

	const [user, setUser] = useState(null);

	const [displayAccessPrompt, setDisplayAccessPrompt] = useState({
		display: false,

		message: "",
	});

	const [isLoading, setIsLoading] = useState(true);

	const [broadcastData, setBroadcastData] = useState(null);

	const [healthAlert, setHealthAlert] = useState(null);

	const broadcastId = props.match.params.broadcastId;

	//GA page view

	useEffect(() => {
		// ReactGA.pageview(window.location.pathname + window.location.search);

		getBroadcast();
	}, []);

	// Fetch broadcastData if "paymentSucceeded" redux state changes to TRUE.

	// This is for if a user buys a pass while on this page.

	useEffectSkipFirst(() => {
		if (paymentSucceeded) {
			setIsLoading(true);

			getBroadcast();
		}
	}, [paymentSucceeded]);

	useEffect(() => {
		if (broadcastData !== null) {
			authenticateUser();
		}
	}, [broadcastData]);

	let checkBroadcastStartLoop = () => {
		console.log("init loop");

		// Begin a loop that waits for the broadcasts scheduled start time to occur.

		let loop = setInterval(() => {
			console.log("looping");

			let broadcastStart = new Date(broadcastData.date);

			let now = new Date();

			if (broadcastStart <= now) {
				clearInterval(loop);

				setTimeout(() => {
					setStartTime(null);

					getBroadcast();
				}, 15000);
			}
		}, 2000);
	};

	// let checkHealth = () => {

	//  switch (broadcastData.health.toLowerCase()) {

	//      case "yellow":

	//          setHealthAlert({

	//              BgColor: "rgb(255,221,37)",

	//              TextColor: "black",

	//              message:

	//                  "We're aware of technical issues with this broadcast and are working to resolve it.",

	//          });

	//          break;

	//      case "red":

	//          setHealthAlert({

	//              BgColor: "red",

	//              TextColor: "black",

	//              message:

	//                  "We're aware of technical issues with this broadcast and are working to resolve it.",

	//          });

	//          break;

	//      default:

	//          setHealthAlert(null);

	//  }

	// };

	const authenticateUser = async () => {
		await Auth.currentAuthenticatedUser({ bypassCache: true })

			.then((user) => {
				setUser(user);

				if (broadcastData.revenue_model.toLowerCase() === "free") {
					console.log("free");

					setDisplayAccessPrompt({
						display: false,

						message: "",
					});

					if (broadcastData.status === "scheduled") {
						console.log("scheduled");

						setStartTime(broadcastData.date);

						checkBroadcastStartLoop();
					} else {
						// console.log("live");

						// if (broadcastData.video_src) {

						//  setVidSrc(broadcastData.video_src);

						//  // checkHealth();

						// } else {

						//  setError(true);

						// }

						// for live and archived

						let accessToken = user.signInUserSession.accessToken.jwtToken;

						let idToken = user.signInUserSession.idToken.jwtToken;

						generateMuxToken(idToken, accessToken);
					}

					setIsLoading(false);
				} else {
					console.log("not free");

					let passExpiration = new Date(user.attributes["custom:passEnd"]);

					if (passExpiration > new Date() || paymentSucceeded) {
						setDisplayAccessPrompt({ display: false, message: "" });

						if (broadcastData.status === "scheduled") {
							setStartTime(broadcastData.date);

							checkBroadcastStartLoop();
						} else {
							// for live and archived

							let accessToken = user.signInUserSession.accessToken.jwtToken;

							let idToken = user.signInUserSession.idToken.jwtToken;

							generateMuxToken(idToken, accessToken);

							// checkHealth();
						}
					} else {
						setDisplayAccessPrompt({
							display: true,

							message: "No valid pass",
						});
					}

					setIsLoading(false);
				}
			})

			.catch(() => {
				setDisplayAccessPrompt({
					display: true,

					message: "User not logged in",
				});

				setIsLoading(false);
			});
	};

	const generateMuxToken = async (idToken, accessToken) => {
		let tokenRetryCount = 0;

		const getToken = async () => {
			await fetch(
				`https://royia0wuq3.execute-api.us-east-2.amazonaws.com/prod/viewer/token`,

				{
					method: "POST",

					headers: {
						Authorization: idToken,
					},

					body: JSON.stringify({
						broadcast: broadcastData,

						token: accessToken,
					}),
				}
			)
				.then((res) => res.json())

				.then((data) => {
					// FIX FOR EDGE CASE: When user pays for pass while on this page.

					// - Takes a bit for pass to update in database.

					// - Keep calling this until valid token is returned.

					// - Throw error after retry limit is reached

					if (data.success === "false") {
						if (data.error === "Inactive Pass") {
							if (tokenRetryCount < 5) {
								setTimeout(() => {
									console.log(tokenRetryCount);

									tokenRetryCount = tokenRetryCount + 1;

									getToken();
								}, 1000);
							} else {
								setError("An error occured. Please try again later.");

								setIsLoading(false);
							}
						} else {
							setError("An error occured. Please try again later.");

							setIsLoading(false);
						}
					} else {
						if (data.url) {
							setVidSrc(data.url);

							setIsLoading(false);
						} else {
							setError(true);
						}
					}
				})

				.catch(() => {
					setError("An error occured. Please try again later.");

					setIsLoading(false);
				});
		};

		getToken();
	};

	const getBroadcast = () => {
		setError(null);

		const fetchBroadcast = () => {
			fetch(`https://arbiter.musco.io/pub/broadcast?id=${broadcastId}`)
				.then((response) => response.json())

				.then((data) => {
					console.log(data);

					if (data.length > 0) {
						setBroadcastData(data[0]);
					} else {
						throw new Error();
					}
				})

				.catch(() => {
					setError("An error occured. Please try again later.");

					setIsLoading(false);
				});
		};

		fetchBroadcast();
	};

	const onSelectChange = (e) => {
		let selectedOption = e.target.value;

		switch (selectedOption) {
			case "streaming":
				setTabSelected("streaming");

				break;

			case "scheduled":
				setTabSelected("scheduled");

				break;

			case "archived":
				setTabSelected("archived");

				break;

			default:
				setTabSelected("streaming");
		}
	};

	return (
		<div className={styles.broadcast}>
			<Helmet>
				<title>MuscoVision</title>

				<meta name="description" content="Broadcast" />
			</Helmet>

			{/* {error ? <Redirect to="/error/broadcast-not-found" /> : null} */}

			<section className={styles.broadcastVideo}>
				<div className={styles.videoFrame}>
					<div className={styles.playerWrapper}>
						{!isLoading ? (
							<>
								{displayAccessPrompt.display ? (
									<AccessPrompt message={displayAccessPrompt.message} />
								) : (
									<>
										{error !== null ? (
											<div className={styles.errorScreen}>
												<div className={styles.overlay}>
													<p>{error}</p>
												</div>
											</div>
										) : (
											<>
												{startTime ? (
													<div className={styles.countDownScreen}>
														<p>
															This broadcast is scheduled to start on <Moment format="MMMM DD" date={startTime} /> at{" "}
															<Moment format="hh:mm A" date={startTime} />
														</p>
													</div>
												) : null}

												{healthAlert ? (
													<div className={styles.healthAlert} style={{ backgroundColor: healthAlert.BgColor }}>
														<p style={{ color: healthAlert.TextColor }}>{healthAlert.message}</p>
													</div>
												) : null}

												{vidSrc !== null ? (
													<>
														<VideoPlayer
															src={vidSrc}
															broadcastData={broadcastData}
															user={user}
															setPlayerError={() => {
																setPlayerError("An error occured. Please try again later.");
															}}
														/>

														{playerError ? (
															<div className={styles.errorScreen}>
																<div className={styles.overlay}>
																	<p>{playerError}</p>
																</div>
															</div>
														) : null}
													</>
												) : null}
											</>
										)}
									</>
								)}
							</>
						) : (
							<div className={styles.loadingScreen}>
								<Spinner color="255, 255, 255" />
							</div>
						)}
					</div>
				</div>
			</section>

			<section className={styles.bottomContent}>
				<Info broadcastData={broadcastData} isLoading={isLoading || broadcastData === null} />

				<section className={styles.broadcastNav}>
					<nav>
						<ul>
							<li
								style={tabSelected === "streaming" ? { borderBottom: "4px solid rgb(0, 94, 164)" } : null}
								onClick={() => setTabSelected("streaming")}
							>
								LIVE
							</li>

							<li
								style={tabSelected === "scheduled" ? { borderBottom: "4px solid rgb(0, 94, 164)" } : null}
								onClick={() => setTabSelected("scheduled")}
							>
								UPCOMING
							</li>

							<li
								style={tabSelected === "archived" ? { borderBottom: "4px solid rgb(0, 94, 164)" } : null}
								onClick={() => setTabSelected("archived")}
							>
								RECENT
							</li>
						</ul>
					</nav>
				</section>

				<section className={styles.broadcastNavMobile}>
					<select onChange={(e) => onSelectChange(e)} ref={select}>
						<option
							value={"streaming"}
							style={tabSelected === "streaming" ? { borderBottom: "4px solid rgb(0, 94, 164)" } : null}
						>
							LIVE
						</option>

						<option
							value={"scheduled"}
							style={tabSelected === "scheduled" ? { borderBottom: "4px solid rgb(0, 94, 164)" } : null}
						>
							UPCOMING
						</option>

						<option
							value={"archived"}
							style={tabSelected === "archived" ? { borderBottom: "4px solid rgb(0, 94, 164)" } : null}
						>
							RECENT
						</option>
					</select>
				</section>
			</section>

			<section className={styles.expandableList}>
				<GetBroadcasts
					facilityID={broadcastData ? broadcastData.site_id : null}
					hideTitle
					updateParent={() => null}
					isLoading={isLoading || broadcastData === null}
					viewerStatus={tabSelected}
				/>
			</section>
		</div>
	);
}

export default withRouter(Broadcast);
