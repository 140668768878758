import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import styles from "./Auth.module.css";
import { useDispatch } from "react-redux";
import { setCurrentModal, setLoading } from "../../../redux/actions/modal";
import { setUserToConfirm } from "../../../redux/actions/auth";
import ReactGA from "react-ga4";
import PasswordChecklist from "react-password-checklist";
import logo from "../../../img/MuscoVision_logo-blue-grey.svg";

const Register = () => {
	const dispatch = useDispatch();
	const [errorMessage, setErrorMessage] = useState(null);

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [name, setName] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [fieldErrors, setFieldErrors] = useState({
		name: null,
		email: null,
		password: null,
		confirmPassword: null,
	});

	useEffect(() => {
		// ReactGA.pageview("register_modal");
	}, []);

	const handleSignUp = (e) => {
		e.preventDefault();
		setFieldErrors({
			email: null,
			password: null,
			confirmPassword: null,
		});
		setErrorMessage(null);
		const validateFields = new Promise((resolve, reject) => {
			let errors = {
				name: null,
				email: null,
				password: null,
				confirmPassword: null,
			};
			let errorFound = false;
			if (name === "") {
				errorFound = true;
				errors.name = "Please enter your full name";
			}
			if (email === "") {
				errorFound = true;
				errors.email = "Please enter your email address";
			} else {
				// Validate the email with a regex.
				// Verifies that the text is proper email format
				const res =
					/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				if (!res.test(String(email).toLowerCase())) {
					errorFound = true;
					errors.email = "Email is invalid";
				}
			}

			if (password === "") {
				errorFound = true;
				errors.password = "Please create your password";
			} else if (password.length < 10) {
				errors.password = "Password must be at least 10 characters";
			}
			if (confirmPassword === "") {
				errorFound = true;
				errors.confirmPassword = "Please confirm your password";
			} else if (confirmPassword !== password) {
				errorFound = true;
				errors.confirmPassword = "Passwords do not match";
			}

			if (!errorFound) {
				resolve();
			} else reject(errors);
		});
		validateFields
			.then(() => {
				console.log("resolved");
				dispatch(setLoading({ isLoading: true, message: "Registering..." }));
				signup();
			})
			.catch((err) => {
				console.log(err);
				setFieldErrors(err);
			});
	};

	const signup = async () => {
		// const d = new Date();
		// let text = d.toUTCString();
		await Auth.signUp({
			username: email,
			password: password,
			attributes: {
				name: name,
				updated_at: new Date().getTime().toString(),
				"custom:userType": "null",
				"custom:passStart": new Date().toString(),
				"custom:passEnd": new Date().toString(),
			},
		})
			.then((res) => {
				ReactGA.event({
					category: "Auth Events",
					action: "register",
					label: "Register", // optional
				});
				dispatch(setLoading({ isLoading: false, message: "" }));
				dispatch(setUserToConfirm({ email: email, password: password }));
				dispatch(setCurrentModal("confirm_account"));
			})
			.catch((err) => {
				console.log(JSON.parse(JSON.stringify(err)));
				dispatch(setLoading({ isLoading: false, message: "" }));

				switch (err.name) {
					case "UsernameExistsException":
						setErrorMessage("There is already a user with this email.");
						break;
					case "AuthError":
						if (err.log) {
							if (err.log === "Username cannot be empty") {
								setErrorMessage("Email cannot be empty");
							} else {
								setErrorMessage(err.log);
							}
						}

						break;
					default:
						setErrorMessage("Registration unsuccessful.");
				}
			});
	};

	return (
		<div className={styles.formWrapper}>
			<img alt="logo" style={{ marginBottom: "3rem" }} src={logo} />
			<form onSubmit={handleSignUp}>
				<h3
					style={{
						color: "black",
						marginBottom: "1.5rem",
						textAlign: "center",
					}}
				>
					CREATE YOUR ACCOUNT
				</h3>
				{errorMessage ? <span className={styles.errorMessage}>{errorMessage}</span> : null}

				<div className={styles.inputGroup}>
					<label className={styles.label}>FULL NAME</label>
					<input autoFocus type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="NAME" />
					{fieldErrors.name !== null ? <span className={styles.errorMessage}>{fieldErrors.name}</span> : null}
				</div>
				<div className={styles.inputGroup}>
					<label className={styles.label}>EMAIL</label>
					<input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="EMAIL ADDRESS" />
					{fieldErrors.email !== null ? <span className={styles.errorMessage}>{fieldErrors.email}</span> : null}
				</div>

				<div className={styles.inputGroup}>
					<label className={styles.label}>CREATE PASSWORD</label>
					<input
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						type="password"
						placeholder="PASSWORD (CASE SENSITIVE)"
					/>
					{fieldErrors.password !== null ? <span className={styles.errorMessage}>{fieldErrors.password}</span> : null}
				</div>

				<div className={styles.inputGroup}>
					<label className={styles.label}>CONFIRM PASSWORD</label>
					<input
						value={confirmPassword}
						onChange={(e) => setConfirmPassword(e.target.value)}
						type="password"
						placeholder="PASSWORD (CASE SENSITIVE)"
					/>
					{fieldErrors.confirmPassword !== null ? (
						<span className={styles.errorMessage}>{fieldErrors.confirmPassword}</span>
					) : null}
				</div>
				{password.length > 0 && (
					<>
						<span className={styles.passwordRequirementsLabel}>Password Requirements:</span>

						<PasswordChecklist
							className={styles.passwordChecklist}
							rules={["minLength", "specialChar", "number", "lowercase", "capital", "match"]}
							minLength={10}
							value={password}
							valueAgain={confirmPassword}
							validColor="#4BCA81"
							invalidColor="#df6f69"
						/>
					</>
				)}
				<button className={styles.submitBtn} onClick={handleSignUp}>
					CONTINUE
				</button>
			</form>

			<p style={{ color: "black", marginTop: "1rem" }}>
				Already have an account?{" "}
				<span
					onClick={() => dispatch(setCurrentModal("login"))}
					style={{ color: "rgb(0, 93, 164)", cursor: "pointer" }}
				>
					Log in here
				</span>
			</p>
		</div>
	);
};
export default Register;
